import { Divider } from "@mui/material"
import React from "react"
import { useTranslatorEditorContext } from "../../context/translator-editor"
import ExtraOptions from "./ExtraOptions"
import ResourceLayerList from "./ResourceLayerList"

export default function RightSidebar() {
  const { data } = useTranslatorEditorContext()

  return (
    <div
      className={`flex flex-col h-full py-6 ${
        data?.status !== "DONE" && data?.status !== "PENDING"
          ? ` opacity-50 pointer-events-none`
          : ``
      }`}
    >
      <ResourceLayerList />
      <Divider className="mx-6 my-6" />
      <ExtraOptions />
    </div>
  )
}
