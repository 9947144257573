import React, { useEffect, useMemo } from "react"
import { FiArrowLeft, FiArrowRight, FiChevronDown } from "react-icons/fi"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router"
import { useTranslatorEditorContext } from "../../../context/translator-editor"
import { TranslatorJobsService } from "../../../services/TranslatorJobsService"
import { TranslatorJobContainerResponseDto } from "../../../types/translator"
import { MyButton } from "../../Button"
import { MyLoader } from "../../Loader"
import { MyMenu, MyMenuItem } from "../../Menu"
import { FaSortDown } from "react-icons/fa"
import { MyChip } from "../../Chip"
import { GrayToken } from "../../../utils/tokens"

type Props = {
  tjContainer: TranslatorJobContainerResponseDto
}

export default function TranslationJobList({ tjContainer }: Props) {
  //  ======================================================================
  //  State
  //  ======================================================================

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const { translatorJobContainerId, translatorJobId } = useParams()
  const { data: tjJob, isLoading: tjJobLoading } = useTranslatorEditorContext()

  const ids = useMemo(() => {
    if (tjContainer) {
      return Object.entries(tjContainer?.jobs.ids as object)
        .sort(([keyA], [keyB]) => parseInt(keyA) - parseInt(keyB))
        .map(([_, value]) => value)
    } else {
      return []
    }
  }, [tjContainer])

  console.log(ids)

  //  ======================================================================
  //  Queries
  //  ======================================================================

  const { data, isLoading, refetch } = useQuery({
    queryFn: () => TranslatorJobsService.getByIds(ids),
    enabled: !!tjContainer?.jobs.ids,
    queryKey: ["translator-jobs", translatorJobContainerId]
  })

  //  ======================================================================
  //  Effects
  //  ======================================================================

  //  ======================================================================
  //  Handlers
  //  ======================================================================

  const onCloseMenu = () => setAnchorEl(null)

  const handleNextJob = () => {
    if (data && tjJob) {
      if (data.length - 1 == tjJob.ordinal) return

      navigate(
        `/editor/${translatorJobContainerId}/${data[tjJob.ordinal + 1].id}`
      )
    }
  }

  const handlePrevJob = () => {
    if (data && tjJob) {
      if (tjJob.ordinal == 0) return

      navigate(
        `/editor/${translatorJobContainerId}/${data[tjJob.ordinal - 1].id}`
      )
    }
  }

  return (
    <div className="flex items-center">
      <MyButton
        disabled={!translatorJobId}
        onClick={handlePrevJob}
        variant="transparent"
        size="small"
      >
        <FiArrowLeft size={18} />
      </MyButton>
      <MyButton
        sx={{ height: "34px" }}
        variant="transparent"
        className="mx-2"
        size="small"
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          if (
            data &&
            data.some((el) => el.status !== "DONE" && el.status !== "PENDING")
          )
            refetch()
        }}
      >
        {tjJob && (
          <>
            <MyChip variant={tjJob.status} label={tjJob.status} />
            <span className="ml-2">Фрейм #{tjJob.ordinal}</span>
            <FiChevronDown size={16} className="ml-2" />
          </>
        )}

        {tjJobLoading && <MyLoader size={16} />}

        {!tjJob && !tjJobLoading && (
          <>
            <span className="text-gray-100">Вибір</span>
            <FiChevronDown size={16} className="ml-2" />
          </>
        )}
      </MyButton>
      <MyMenu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: -12,
          horizontal: "center"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseMenu}
        PaperProps={{
          sx: {
            maxHeight: 360,
            background: GrayToken[800]
          }
        }}
      >
        {isLoading && (
          <MyMenuItem>
            <MyLoader size={24} />
          </MyMenuItem>
        )}
        {data &&
          data.map((job) => (
            <MyMenuItem
              className={job.id === translatorJobId ? "bg-gray-700" : undefined}
              onClick={() => {
                onCloseMenu()
                navigate(`/editor/${translatorJobContainerId}/${job.id}`)
              }}
            >
              <MyChip variant={job.status} label={job.status} />
              <span className="mx-2">Фрейм #{job.ordinal}</span>
            </MyMenuItem>
          ))}
      </MyMenu>
      <MyButton
        disabled={!translatorJobId}
        onClick={handleNextJob}
        variant="transparent"
        size="small"
      >
        <FiArrowRight size={18} />
      </MyButton>
    </div>
  )
}
