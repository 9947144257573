import { Box } from "@mui/material"
import { PropsWithChildren } from "react"
import { NavLink } from "react-router-dom"

interface Props extends PropsWithChildren {
  to: string
}
const NavItem: React.FC<Props> = ({ to, children }) => {
  const className =
    "hm__nav-item h-16 flex items-center border-b-2 border-transparent transition duration-150 ease-in-out font-medium  hover:text-primary-500"

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        [
          className,
          isActive ? "text-primary-500 !border-primary-500" : "text-gray-400"
        ]
          .filter(Boolean)
          .join(" ")
      }
    >
      {children}
    </NavLink>
  )
}

const Nav: React.FC = () => {
  return (
    <Box
      className="flex items-center ml-12"
      sx={{
        ".hm__nav-item + .hm__nav-item": {
          marginLeft: "24px"
        }
      }}
    >
      <NavItem to="/">Головна</NavItem>
      <NavItem to="/parser">Парсинг</NavItem>
    </Box>
  )
}

export default Nav
