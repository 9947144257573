import moment from "moment"
import React from "react"
import {
  FiChevronDown,
  FiEdit2,
  FiLink,
  FiMoreHorizontal,
  FiPlus,
  FiTrash
} from "react-icons/fi"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"
import { ChapterJobResponseDto } from "../../types/parser"
import { truncate } from "../../utils"
import { MyIconButton } from "../Button"
import { MyChip } from "../Chip"
import { MangaPreviewPopup, MangaPreviewTooltip } from "../manga/MangaPreview"
import { MyMenu, MyMenuItem } from "../Menu"
import {
  MyTable,
  MyTableBody,
  MyTableCell,
  MyTableHead,
  MyTableRow
} from "../Table"

type ExcludeCell = "translate"

const ChapterJob: React.FC<
  ChapterJobResponseDto & { exclude?: ExcludeCell }
> = ({
  id,
  srcName,
  chapterUrl,
  chapterNum,
  status,
  translateJobContainerId,
  modifiedAt,
  volume,
  exclude
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  //  ===============================================s=======================
  //  Mutate
  //  ======================================================================

  // const {mutate: mutateTranslatorAI, isLoading: mutatingTranslatorAI, error: errorTranslatorAI} = useMutation({
  //   mutationFn: () =>
  // })

  //  ===============================================s=======================
  //  Handlers
  //  ======================================================================

  const handleClose = () => setAnchorEl(null)

  return (
    <MyTableRow>
      <MyTableCell>
        <MyChip variant={status} label={status} />
      </MyTableCell>
      <MyTableCell>
        {volume} / {chapterNum}
      </MyTableCell>
      <MyTableCell>
        <a
          href={chapterUrl}
          className="text-gray-700 hover:text-gray-600 hover:underline"
          target="_blank"
        >
          {truncate(chapterUrl, 24)}
        </a>
      </MyTableCell>
      <MyTableCell>
        {moment(modifiedAt).format("DD / MM / YYYY - HH:mm")}
      </MyTableCell>
      {exclude !== "translate" && (
        <MyTableCell>
          {translateJobContainerId ? (
            <Link
              to={`/editor/${translateJobContainerId}`}
              className="text-primary-400 flex items-center hover:text-primary-600 transition duration-150"
            >
              <FiLink size={16} className="mr-3" />
              Дивитися
            </Link>
          ) : status !== "DONE" ? (
            "-"
          ) : (
            <Link
              className="transition duration-150 flex items-center text-gray-400 hover:text-gray-700"
              to={`/parser/rsc/${id}`}
            >
              <FiPlus size={16} className="mr-3" />
              Створити
            </Link>
          )}
        </MyTableCell>
      )}
      <MyTableCell className="flex justify-end">
        <MyIconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          variant="transparent"
        >
          <FiEdit2 size={18} />
        </MyIconButton>
        <MyMenu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: -12,
            horizontal: "right"
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {/* ======================================== */}
          {/* Action List */}
          {/* ======================================== */}

          <MyMenuItem>
            <div className="flex items-center justify-center rounded-lg h-9 w-9 bg-gray-25 mr-3">
              <FiTrash size={18} />
            </div>
            ...
          </MyMenuItem>
        </MyMenu>
      </MyTableCell>
    </MyTableRow>
  )
}

export default ChapterJob
