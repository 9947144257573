import { Box, GlobalStyles } from "@mui/material"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router"
import { MyButton } from "../components/Button"
import { MyChip } from "../components/Chip"
import { enqueueSnackbar } from "notistack"
import {
  MangaPreviewPopup,
  MangaPreviewTooltip
} from "../components/manga/MangaPreview"
import Frame from "../components/parser/rsc/Frame"
import { useAuth } from "../context/auth"
import { ChapterJobsService } from "../services/ChapterJobsService"
import { MangaService } from "../services/MangaService"
import { TranslatorJobsService } from "../services/TranslatorJobsService"
import { ChapterJobRequestDto, ChapterJobResponseDto } from "../types/parser"
import { TranslatorJobContainerRequestDto } from "../types/translator"
import { arrayMove } from "../utils"
import {
  MyTable,
  MyTableBody,
  MyTableCell,
  MyTableHead,
  MyTableRow
} from "../components/Table"
import { MyLoader } from "../components/Loader"
import ChapterJob from "../components/parser/ChapterJob"

export default function ChapterJobRSC() {
  //  ======================================================================
  //  State
  //  ======================================================================

  const { chapterJobId } = useParams()
  const { user } = useAuth()
  const [frames, setFrames] = useState<string[]>([])

  //  ======================================================================
  //  Queries
  //  ======================================================================

  const { data, isLoading, error } = useQuery<ChapterJobResponseDto, Error>({
    queryFn: () =>
      ChapterJobsService.getById(
        chapterJobId as string,
        user?.idToken as string
      ),
    queryKey: ["chapter-rsc", chapterJobId],
    enabled: !!user
  })

  const {
    data: manga,
    isLoading: loadingManga,
    error: erorManga
  } = useQuery({
    queryFn: () => MangaService.getById(data?.mangaId as string),
    queryKey: ["manga", data?.mangaId as string],
    enabled: !!data
  })

  //  ======================================================================
  //  Mutations
  //  ======================================================================

  const {
    data: dataTAI,
    isLoading: loadingTAI,
    mutate: mutateTAI
  } = useMutation({
    mutationFn: (data: TranslatorJobContainerRequestDto) =>
      TranslatorJobsService.create(data, user?.idToken as string),
    onSuccess: (id: string, vars) => {
      mutateCJ({
        data: {
          translateJobContainerId: id
        },
        chapterJobId: data?.id as string
      })
    }
  })

  const {
    data: dataCJ,
    isLoading: loadingCJ,
    mutate: mutateCJ
  } = useMutation({
    mutationFn: (data: {
      chapterJobId: string
      data: Partial<ChapterJobRequestDto>
    }) =>
      ChapterJobsService.patch(
        data.data,
        data.chapterJobId,
        user?.idToken as string
      )
  })

  //  ======================================================================
  //  Effects
  //  ======================================================================

  useEffect(() => {
    if (data?.result) {
      setFrames(
        Object.entries(data.result.resourceIds)
          .sort(([keyA], [keyB]) => {
            return parseInt(keyA) - parseInt(keyB)
          })
          .map(([key, value]) => value)
      )
    }
  }, [data])

  //  ======================================================================
  //  Handlers
  //  ======================================================================

  const handleDeleteFrame = useCallback(
    (id: string) => {
      const filteredFrames = frames.filter((frameId) => frameId !== id)
      setFrames(filteredFrames)
    },
    [frames]
  )

  const handleMoveFrame = useCallback((oldIndex: number, newIndex: number) => {
    setFrames((state) => arrayMove([...state], oldIndex, newIndex))
  }, [])

  const handleSubmit = () => {
    if (frames.length == 0) return
    if (!data || !manga) return
    if (!data?.result) return
    mutateTAI(
      {
        chapterNum: data.chapterNum,
        volume: data.volume,
        mangaId: data.mangaId,
        title: manga.title,
        mangaAlternativeTitle: manga.alternativeTitle,
        resources: {
          ids: frames.reduce(
            (prev, current, i) => ({ ...prev, [i]: current }),
            {}
          )
        },
        extraOptions: {}
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Translator successful started", {
            variant: "info"
          })
        }
      }
    )
  }

  return (
    <div className="container mx-auto py-6 flex-grow">
      <div className="flex items-center justify-between mb-6">
        <p className="text-lg font-bold text-gray-700">Створити переклад</p>
        <MyButton
          onClick={handleSubmit}
          disabled={loadingTAI || loadingCJ}
          isLoading={loadingTAI || loadingCJ}
          size="small"
        >
          Створити
        </MyButton>
      </div>
      <MyTable>
        <MyTableHead>
          <MyTableRow>
            <MyTableCell>Статус</MyTableCell>
            <MyTableCell>Топ / Епізод</MyTableCell>
            <MyTableCell>Джерело</MyTableCell>
            <MyTableCell>Останні зміни</MyTableCell>
            <MyTableCell align="right"></MyTableCell>
          </MyTableRow>
        </MyTableHead>
        <MyTableBody>
          {isLoading && (
            <MyTableCell className="px-4 py-3" colSpan={6}>
              <div className="w-full">
                <MyLoader size={24} />
              </div>
            </MyTableCell>
          )}
          {error && (
            <MyTableCell
              className="px-4 py-3 text-center text-error-500"
              align="center"
              colSpan={6}
            >
              {error.message}
            </MyTableCell>
          )}
          {data && <ChapterJob exclude="translate" {...data} />}
        </MyTableBody>
      </MyTable>
      <div className="grid grid-cols-12 mt-6">
        <div className="col-span-12">
          <Box
            sx={{
              ".hm-chapter-rsc__create-translation-job + .hm-chapter-rsc__create-translation-job":
                {
                  marginTop: "48px"
                }
            }}
          >
            {frames.map((id, i) => (
              <Frame
                key={id}
                moveFrame={handleMoveFrame}
                handleDelete={handleDeleteFrame}
                rscId={id}
                index={i}
                frames={frames}
              />
            ))}
          </Box>
        </div>
      </div>
    </div>
  )
}
