import { Avatar, AvatarProps } from "@mui/material"

interface Props {
  src: string
  size: number
  muiProps?: AvatarProps
}

export const MyAvatar: React.FC<Props> = ({ src, size, muiProps }) => {
  return (
    <Avatar
      alt=""
      src={src || ""}
      {...muiProps}
      sx={{
        borderRadius: "10px !important",
        objectFit: "cover",
        objectPosition: "center",
        height: size,
        width: size,
        ...muiProps?.sx
      }}
    />
  )
}
