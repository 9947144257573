import { alpha, Select, SelectProps, styled } from "@mui/material"
import { PropsWithChildren } from "react"
import { Control, Controller } from "react-hook-form"
import { FiChevronDown } from "react-icons/fi"
import { ErrorToken, GrayToken, PrimaryToken } from "../utils/tokens"

interface Props {
  control: Control<any>
  name: string
  muiProps?: SelectProps
}

export const MySelectFilled = styled(Select)(({ theme, size }) => ({
  borderRadius: 4,

  "& fieldset": {
    border: "none"
  },

  "&.Mui-error fieldset": {
    borderColor: theme.palette.error.main,
    borderWidth: 1,
    borderStyle: "solid"
  },

  "&.Mui-focused.Mui-error fieldset": {
    borderColor: theme.palette.error.main,
    borderWidth: 1,
    borderStyle: "solid"
  },

  ...(size === "small" && {
    "& .MuiSelect-select": {
      padding: "10px 16px"
    }
  })
}))

export const MySelect = styled(Select)(({ theme, size }) => ({
  borderRadius: 4,
  "& fieldset": {
    borderColor:
      theme.palette.mode == "light"
        ? GrayToken[100]
        : alpha(GrayToken[100], 0.2)
  },

  "&.Mui-error fieldset": {
    borderColor: theme.palette.error.main,
    borderWidth: "1px !important",
    borderStyle: "solid"
  },

  "&.Mui-focused.Mui-error fieldset, &.Mui-error fieldset": {
    borderColor: ErrorToken[300],
    borderWidth: "1px !important"
  },

  ...(size === "small" && {
    "& .MuiSelect-select": {
      padding: "10px 16px"
    }
  }),

  "&.Mui-focused fieldset, &:hover fieldset": {
    borderColor: `${PrimaryToken[400]} !important`,
    borderWidth: "1px !important"
  }
}))

export const MySelectC: React.FC<Props & PropsWithChildren> = ({
  control,
  name,
  muiProps,
  children
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, name, ref },
        fieldState: { error }
      }) =>
        muiProps?.variant == "filled" ? (
          <MySelectFilled
            onChange={onChange}
            inputRef={ref}
            value={value}
            error={!!error}
            MenuProps={{
              sx: {
                "& .MuiPaper-root": {
                  borderRadius: 4,
                  boxShadow: "0px 12px 25px rgba(73, 75, 80, 0.05)",
                  maxHeight: 320
                }
              }
            }}
            IconComponent={FiChevronDown}
            {...muiProps}
          >
            {children}
          </MySelectFilled>
        ) : (
          <MySelect
            onChange={onChange}
            inputRef={ref}
            value={value}
            error={!!error}
            MenuProps={{
              sx: {
                "& .MuiPaper-root": {
                  borderRadius: 4,
                  boxShadow: "0px 12px 25px rgba(73, 75, 80, 0.05)",
                  maxHeight: 320
                }
              }
            }}
            IconComponent={FiChevronDown}
            {...muiProps}
          >
            {children}
          </MySelect>
        )
      }
    />
  )
}
