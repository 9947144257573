import { BsQuestion, BsQuestionCircle } from "react-icons/bs"
import { useNavigate } from "react-router"
import User from "../auth/User"
import { MyIconButton } from "../Button"
import Nav from "./Nav"

export default function Header() {
  const navigate = useNavigate()

  return (
    <div className="bg-[#fff] flex items-center border-b border-gray-50">
      <div className="container flex items-center justify-between mx-auto">
        <div className="flex items-center">
          <p className="flex items-center font-bold">
            <img className="w-9 mr-3" src="/imgs/swg-11.png" />
            Honey Root
          </p>
          <Nav />
        </div>
        <div className="flex items-center">
          <MyIconButton onClick={() => navigate("/docs")} className="mr-3">
            <BsQuestionCircle size={18} />
          </MyIconButton>
          <User />
        </div>
      </div>
    </div>
  )
}
