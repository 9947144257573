import React from "react"
import { MyLoader } from "../Loader"
import ResourceLayer from "./ResourceLayer"

export default function ResourceLayerList() {
  return (
    <div>
      <div className="flex items-baseline justify-between mx-6">
        <p className="text-gray-700 font-bold">Ресурси</p>
      </div>

      <div className="mt-3 flex-grow overflow-y-auto">
        <ResourceLayer title="Output" type="output" />
        <ResourceLayer title="Original Resource" type="resourceId" />
        <ResourceLayer title="Bbox Mask" type="bboxResourceId" />
        <ResourceLayer
          title="Scatter Lines"
          type="scatterLinesBboxResourceId"
        />
        <ResourceLayer title="Deceted Text Mask" type="maskFinalResourceId" />
        <ResourceLayer title="Inpainted Mask" type="inPaintedResourceId" />
      </div>
    </div>
  )
}
