import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import {
  User as UserInfo,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  FacebookAuthProvider,
  UserCredential,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider
} from "@firebase/auth"
import { auth } from "../utils/firebase"
import { UserService } from "../services/UserService"
import { UserModel } from "../types"
import moment from "moment"

interface AppContextInterface {
  user: User | null
  isLoading: boolean
  signUpWithEmail: (email: string, password: string) => Promise<UserCredential>
  signInWithEmail: (email: string, password: string) => Promise<UserCredential>
  signInWithFB: () => Promise<UserCredential>
  signInWithGoogle: () => Promise<UserCredential>
  logout: () => void
}

const AuthContext = createContext<AppContextInterface>(
  {} as AppContextInterface
)

interface User extends UserInfo {
  idToken: string
  role: string[]
}

interface State {
  user: User | null
  isLoading: boolean
}

export const AuthContextProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  //  ======================================================================
  //  State
  //  ======================================================================
  const [state, setState] = useState<State>({
    user: null,
    isLoading: true
  })

  //  ======================================================================
  //  Auth Methods
  //  ======================================================================

  const signUpWithEmail = async (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  const signInWithEmail = async (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
  }

  const signInWithFB = async () => {
    return signInWithPopup(auth, new FacebookAuthProvider())
  }

  const signInWithGoogle = async () => {
    return signInWithPopup(auth, new GoogleAuthProvider())
  }

  //  ======================================================================
  //  Logout
  //  ======================================================================

  const logout = async () => {
    signOut(auth).then(() => {
      setState((state) => ({ ...state, user: null }))
    })
  }

  //  ======================================================================
  //  Hooks
  //  ======================================================================

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const { uid } = user
        const userDocSnap = await UserService.getById(uid)
        if (!userDocSnap.exists()) {
          UserService.create({
            uid,
            displayName: (user.displayName || user.email) as string
          })
        } else {
          const userModel = userDocSnap.data() as UserModel
          user.getIdToken().then((idToken) => {
            console.log(idToken)
            setState((state) => ({
              isLoading: false,
              user: { ...user, idToken, role: userModel.role || [] }
            }))
          })
        }
      } else {
        setState({ user: null, isLoading: false })
      }
    })
    return () => unsubscribe()
  }, [])

  const value = useMemo<AppContextInterface>(
    () => ({
      user: state.user,
      signUpWithEmail,
      signInWithEmail,
      signInWithFB,
      logout,
      isLoading: state.isLoading,
      signInWithGoogle
    }),
    [state]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
