import { alpha, createTheme, Theme } from "@mui/material"
import {
  GrayToken,
  PrimaryToken,
  GreenToken,
  ErrorToken,
  FontFamily,
  Breakpoints,
  WarningToken,
  FontHeaders
} from "./tokens"

// ======================================================
// Light Theme
// ======================================================

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: PrimaryToken[500],
      light: PrimaryToken[300]
    },
    secondary: {
      main: PrimaryToken[500],
      light: PrimaryToken[300]
    },
    text: {
      primary: GrayToken[800],
      secondary: GrayToken[600],
      disabled: GrayToken[400]
    },
    success: {
      main: GreenToken[500],
      light: GreenToken[50],
      dark: GreenToken[800]
    },
    warning: {
      main: WarningToken[500],
      light: WarningToken[50],
      dark: WarningToken[800]
    },
    error: {
      main: ErrorToken[500],
      light: ErrorToken[50],
      dark: ErrorToken[800]
    },
    divider: GrayToken[100]
  },
  typography: {
    fontFamily: FontFamily.regular.join(","),
    fontWeightMedium: FontFamily.medium.join(","),
    fontWeightBold: FontFamily.bold.join(","),
    h1: FontHeaders.h1,
    h2: FontHeaders.h2,
    h3: FontHeaders.h3,
    h4: FontHeaders.h4,
    h5: FontHeaders.h5,
    h6: FontHeaders.h6
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgorund: "#ffffff",
          boxShadow: "0px 12px 25px rgba(73, 75, 80, 0.05) !important",
          backgroundImage: "none"
        }
      }
    }
  },
  breakpoints: {
    values: Breakpoints
  }
})

// ======================================================
// Dark Theme
// ======================================================

export const darkTheme = createTheme(theme, {
  palette: {
    mode: "dark",
    text: {
      primary: "#ffffff",
      secondary: alpha(GrayToken[50], 0.75),
      disabled: alpha(GrayToken[50], 0.5)
    },
    divider: alpha(GrayToken[50], 0.1),
    background: {
      default: GrayToken[800],
      paper: GrayToken[800]
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgorund: "#353535",
          backgroundImage: "none"
        }
      }
    }
  }
})
