import { Collapse, Divider } from "@mui/material"
import React, { useCallback, useState } from "react"
import {
  FiArrowDown,
  FiArrowUp,
  FiChevronDown,
  FiChevronUp,
  FiEye,
  FiEyeOff,
  FiMenu,
  FiMinus,
  FiMoreHorizontal,
  FiPlus,
  FiTrash,
  FiType
} from "react-icons/fi"
import { useTranslatorEditorContext } from "../../context/translator-editor"
import { arrayMove, toggleArrayElement } from "../../utils"
import { MyIconButton } from "../Button"
import { MyMenu, MyMenuItem } from "../Menu"

type Props = {
  index: number
  scatterLines: number[]
}

export default function ScatterLine({ scatterLines, index }: Props) {
  // =================================================
  // State
  // =================================================

  const [open, setOpen] = useState(false)
  const [isActive, setActive] = useState(false)
  const { setScatteredLinesOptions, scatteredLinesOptions } =
    useTranslatorEditorContext()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const classes = []
  if (isActive || menuOpen) {
    classes.push("bg-primary-25")
  }

  // =================================================
  // Drag @ Drop Handlers
  // =================================================

  const handleExcludeScatterLine = (scatterLineIndex: number) => {
    const scatteredLines = scatteredLinesOptions?.excludeScatteredLinesIndexes

    if (scatteredLines) {
      const newScatteredLines = toggleArrayElement(
        scatteredLines,
        scatterLineIndex
      )

      setScatteredLinesOptions((state) => ({
        ...state,
        considerOptions: true,
        excludeScatteredLinesIndexes: newScatteredLines
      }))
    }

    handleMenuClose()
  }

  const handleMove = (scatterLineIndex: number) => {
    const scatteredLines = scatteredLinesOptions?.mergeScatteredLinesIndexes
    if (scatteredLines) {
      if (scatteredLines[index].some((el) => el === scatterLineIndex)) return

      const newScatteredLines: Array<number[]> = scatteredLines.map(
        (mergedScatteredLines) =>
          mergedScatteredLines.filter((el) => el !== scatterLineIndex)
      )

      newScatteredLines[index].push(scatterLineIndex)
      newScatteredLines[index].sort((a, b) => a - b)

      setScatteredLinesOptions((state) => ({
        ...state,
        considerOptions: true,
        mergeScatteredLinesIndexes: newScatteredLines
      }))
    }
  }

  const handleDragStart = useCallback(
    (e: React.DragEvent<HTMLDivElement>, index: number) => {
      e.dataTransfer.setData("text/plain", index.toString())
    },
    [scatterLines, index]
  )

  const handleDragEnd = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.dataTransfer.clearData()
    },
    [scatterLines, index]
  )

  const handleDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      setActive(true)
      e.preventDefault()
    },
    [scatterLines, index]
  )

  const handleDragLeave = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      setActive(false)
    },
    [scatterLines, index]
  )

  const handleDragDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setActive(false)
      handleMove(parseInt(e.dataTransfer.getData("text")))
    },
    [scatterLines, index]
  )

  // =================================================
  // Menu Handlers
  // =================================================

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMoveBlock = (direction: -1 | 1) => {
    const scatteredLines = scatteredLinesOptions?.mergeScatteredLinesIndexes
    if (scatteredLines) {
      const newScatteredLines: Array<number[]> = arrayMove(
        scatteredLines,
        index,
        index + direction
      )

      setScatteredLinesOptions((state) => ({
        ...state,
        considerOptions: true,
        mergeScatteredLinesIndexes: newScatteredLines
      }))

      handleMenuClose()
    }
  }

  const handleCreateBlock = (direction: -1 | 1) => {
    const scatteredLines = scatteredLinesOptions?.mergeScatteredLinesIndexes
    if (scatteredLines) {
      const newScatteredLines: Array<number[]> = [...scatteredLines, []]
      const movedScatteredLines = arrayMove(
        newScatteredLines,
        newScatteredLines.length - 1,
        index + direction
      )

      setScatteredLinesOptions((state) => ({
        ...state,
        considerOptions: true,
        mergeScatteredLinesIndexes: movedScatteredLines
      }))

      handleMenuClose()
    }
  }

  const handleDeleteBlock = () => {
    const scatteredLines = scatteredLinesOptions?.mergeScatteredLinesIndexes
    if (scatteredLines) {
      const newScatteredLines: Array<number[]> = [...scatteredLines]
      newScatteredLines.splice(index, 1)

      setScatteredLinesOptions((state) => ({
        ...state,
        considerOptions: true,
        mergeScatteredLinesIndexes: newScatteredLines
      }))
    }

    handleMenuClose()
  }

  return (
    <div
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDragDrop}
      className={classes.join(" ")}
    >
      <button
        onClick={() => setOpen((open) => !open)}
        className={`w-full group flex justify-between items-center px-6 py-3 hover:text-primary-500 hover:bg-primary-25 transition-all duration-100 ease-in-out text-gray-600`}
      >
        <div className="flex items-center">
          <FiType
            size={18}
            className={`mr-3 group-hover:text-primary-500 transition-all duration-100 ease-in-out text-gray-400`}
          />
          Text layer #{index}
        </div>
        <div className="flex items-center opacity-0 transition-all duration-100 ease-in-out group-hover:opacity-100">
          <button
            onClick={handleMenuClick}
            className="hover:bg-primary-50 px-2 py-1 flex items-center mr-3"
          >
            <FiMoreHorizontal size={18} />
          </button>
        </div>
      </button>
      <Collapse unmountOnExit mountOnEnter in={open}>
        <div className={"px-6"}>
          {scatterLines.length == 0 && (
            <div className="w-full text-center text-gray-400 py-3">Пусто</div>
          )}
          {scatterLines.map((scatterLineIndex) => (
            <div
              className={`w-full group flex justify-between items-center px-6 py-3 hover:text-primary-500 hover:bg-primary-25 transition-all duration-100 ease-in-out text-gray-600`}
              onDragStart={(e) => handleDragStart(e, scatterLineIndex)}
              onDragEnd={handleDragEnd}
              draggable
              key={scatterLineIndex}
            >
              <div className="flex items-center">
                <FiMinus
                  size={18}
                  className="mr-3 text-gray-400 group-hover:text-primary-500 transition-all duration-100 ease-in-out"
                />
                Scatter Line #{scatterLineIndex}
              </div>
              <MyIconButton
                onClick={() => handleExcludeScatterLine(scatterLineIndex)}
                size="small"
                variant="transparent"
              >
                {scatteredLinesOptions?.excludeScatteredLinesIndexes?.includes(
                  scatterLineIndex
                ) ? (
                  <FiEyeOff size={18} />
                ) : (
                  <FiEye size={18} />
                )}
              </MyIconButton>
            </div>
          ))}
        </div>
      </Collapse>
      <MyMenu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: -12,
          horizontal: "right"
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MyMenuItem
          onClick={() => handleMoveBlock(-1)}
          className="flex justify-between items-center"
        >
          <div className="flex items-center">
            <FiType size={18} className="mr-3" />
            Перемістити Scatter Lines
          </div>
          <FiArrowUp size={18} className="ml-3" />
        </MyMenuItem>
        <MyMenuItem className="flex justify-between items-center">
          <div className="flex items-center">
            <FiType size={18} className="mr-3" />
            Перемістити Scatter Lines
          </div>
          <FiArrowDown size={18} className="ml-3" />
        </MyMenuItem>
        <MyMenuItem onClick={() => handleDeleteBlock()}>
          <FiTrash size={18} className="mr-3" />
          Видалити блок
        </MyMenuItem>
        <Divider className="mx-4" />
        <MyMenuItem
          onClick={() => handleCreateBlock(-1)}
          className="flex justify-between items-center"
        >
          <div className="flex items-center">
            <FiType size={18} className="mr-3" />
            Створити блок
          </div>
          <FiArrowUp size={18} className="ml-3" />
        </MyMenuItem>
        <MyMenuItem
          onClick={() => handleCreateBlock(1)}
          className="flex justify-between items-center"
        >
          <div className="flex items-center">
            <FiType size={18} className="mr-3" />
            Створити блок
          </div>
          <FiArrowDown size={18} className="ml-3" />
        </MyMenuItem>
      </MyMenu>
    </div>
  )
}
