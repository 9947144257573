import { NavLink } from "react-router-dom"
import { MyButton } from "../components/Button"

export default function CTAPage() {
  return (
    <div className="px-4 md:container mx-auto grow flex flex-col items-center justify-center">
      <div className="flex w-full justify-center">
        <NavLink
          className="transition duration-150 ease-in-out rounded-md bg-gray-900 hover:bg-gray-600 text-[#fff] px-6 py-2 mr-2"
          to="parser"
        >
          Парсинг
        </NavLink>
        <NavLink
          to="docs"
          className="transition hover:bg-gray-50 duration-150 ease-in-out border border-gray-200 rounded-md px-6 py-2"
        >
          Документація
        </NavLink>
      </div>
    </div>
  )
}
