import { alpha, ButtonBase, ButtonBaseProps, styled } from "@mui/material"
import { MyLoader } from "./Loader"
import colors from "tailwindcss/colors"
import { GrayToken, PrimaryToken } from "../utils/tokens"

interface CustomProps {
  variant?:
    | "accent"
    | "secondary"
    | "dark"
    | "outlined"
    | "colorless"
    | "transparent"
  size?: "small" | "medium" | "large"
  fullWidth?: boolean
  isLoading?: boolean
}

export const MyButtonBase = styled(
  ({
    variant,
    isLoading,
    size,
    children,
    ...rest
  }: ButtonBaseProps & CustomProps) => (
    <ButtonBase {...rest}>
      {isLoading ? <MyLoader size={size == "small" ? 14 : 16} /> : children}
    </ButtonBase>
  )
)(({ theme, variant = "accent" }) => ({
  boxSizing: "border-box",
  transition: "all 200ms",

  ...(variant === "accent" && {
    background:
      theme.palette.mode === "light" ? PrimaryToken[25] : PrimaryToken[500],
    color: theme.palette.mode === "light" ? PrimaryToken[500] : GrayToken[50],

    "&:hover": {
      background:
        theme.palette.mode === "light" ? PrimaryToken[50] : PrimaryToken[600]
    }
  }),

  ...(variant === "colorless" && {
    background:
      theme.palette.mode == "light"
        ? alpha(GrayToken[50], 0.5)
        : alpha("#ffffff", 0.075),
    color: theme.palette.mode == "light" ? GrayToken[600] : GrayToken[50],

    "&:hover": {
      background:
        theme.palette.mode == "light"
          ? alpha(GrayToken[50], 1)
          : alpha("#ffffff", 0.2)
    },

    "&:disabled": {
      background:
        theme.palette.mode == "light"
          ? alpha(GrayToken[50], 0.5)
          : alpha("#ffffff", 0.075),
      color: GrayToken[200]
    }
  }),
  ...(variant === "transparent" && {
    background: "transparent",
    color: theme.palette.mode == "light" ? GrayToken[600] : GrayToken[50],

    "&:hover": {
      background:
        theme.palette.mode == "light"
          ? alpha(GrayToken[50], 0.5)
          : alpha("#ffffff", 0.075)
    },

    "&:disabled": {
      background: "transparent",
      color: GrayToken[200]
    }
  })
}))

export const MyButton = styled(MyButtonBase)(
  ({ theme, size = "medium", fullWidth }) => ({
    fontFamily: theme.typography.fontWeightMedium as string,
    borderRadius: 4,
    ...(size === "small" && {
      fontSize: 14,
      padding: "8px 14px"
    }),
    ...(size === "medium" && {
      fontSize: 16,
      padding: "12px 16px"
    }),
    ...(size === "large" && {
      fontSize: 18,
      padding: "16px 24px"
    }),
    ...(fullWidth && {
      width: "100%"
    })
  })
)

export const MyIconButton = styled((props: ButtonBaseProps & CustomProps) => (
  <MyButtonBase variant="colorless" {...props} />
))(({ size = "medium" }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  ...(size === "small" && {
    width: 28,
    height: 28,
    borderRadius: 4,
    fontSize: 16
  }),
  ...(size === "medium" && {
    width: 36,
    height: 36,
    borderRadius: 10,
    fontSize: 18
  }),
  ...(size === "large" && {
    width: 48,
    height: 48,
    borderRadius: 10,
    fontSize: 24
  })
}))
