import { Collapse, Fade } from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import { FiChevronDown } from "react-icons/fi"
import { MangaJobResponseDto } from "../../types/parser"
import { MyIconButton } from "../Button"
import {
  MangaPreview,
  MangaPreviewPopup,
  MangaPreviewTooltip
} from "../manga/MangaPreview"
import {
  MyTable,
  MyTableBody,
  MyTableCell,
  MyTableHead,
  MyTableRow
} from "../Table"
import ChapterJobList from "./ChapterJobList"
import config from "../../config.json"

const MangaJob: React.FC<MangaJobResponseDto> = ({ modifiedAt, id, title }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <MyTableRow>
        <MyTableCell colSpan={1}>
          <div className="flex items-center">
            <MyIconButton
              onClick={() => setOpen((state) => !state)}
              variant="transparent"
              size="small"
              className="mr-3"
            >
              <FiChevronDown className={isOpen ? "rotate-180" : "rotate-0"} />
            </MyIconButton>
            <MangaPreview mangaId={id}>
              <a
                href={`${config.official_domen}/book/${id}`}
                className="text-gray-700 hover:text-gray-600 hover:underline"
                target="_blank"
              >
                {title}
              </a>
            </MangaPreview>
          </div>
        </MyTableCell>
        <MyTableCell colSpan={1}>
          {moment(modifiedAt).format("DD / MM / YYYY - HH:mm")}
        </MyTableCell>
        <MyTableCell colSpan={1} align="right">
          ---
        </MyTableCell>
      </MyTableRow>
      <MyTableRow>
        <MyTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse className="w-full" mountOnEnter unmountOnExit in={isOpen}>
            <div>
              <ChapterJobList mangaJobId={id} />
            </div>
          </Collapse>
        </MyTableCell>
      </MyTableRow>
    </>
  )
}

export default MangaJob
