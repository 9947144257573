import { Slider } from "@mui/material"
import React from "react"
import { FiRefreshCcw } from "react-icons/fi"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router"
import { useTranslatorEditorContext } from "../../context/translator-editor"
import { TranslatorJobsService } from "../../services/TranslatorJobsService"
import { TranslatorJobRequestDto } from "../../types/translator"
import { MyButton } from "../Button"
import { MyLoader } from "../Loader"

export default function ExtraOptions() {
  //  ======================================================================
  //  State
  //  ======================================================================

  const {
    data,
    isLoading,
    extraOptions,
    handleExtraOptions,
    textBlocks,
    handleResetExtraOptions
  } = useTranslatorEditorContext()
  const { translatorJobId, translatorJobContainerId } = useParams()
  const queryClient = useQueryClient()

  //  ======================================================================
  //  Mutating
  //  ======================================================================

  const {
    data: mutatingData,
    isLoading: mutating,
    error: mutatingError,
    mutate
  } = useMutation({
    mutationFn: (data: TranslatorJobRequestDto) => {
      return TranslatorJobsService.patch(data, translatorJobId as string)
    },
    onSuccess: (_, vars) => {
      queryClient.setQueryData(["translator-job", translatorJobId], {
        ...data,
        extraOptions,
        status: vars.status
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["translator-jobs", translatorJobContainerId as string]
      })
    }
  })

  //  ======================================================================
  //  Handlers
  //  ======================================================================

  const handleMutate = () => {
    mutate({
      status: "RE_RENDER",
      extraOptions: {
        considerOptions: true,
        inpaintingDilation: {
          kernel: extraOptions?.inpaintingDilation?.kernel || 1,
          iterations: extraOptions?.inpaintingDilation?.iterations || 0
        }
      },
      ...(JSON.stringify(textBlocks) !==
        JSON.stringify(data?.assets?.translatedSentences) && {
        translatedSentences: textBlocks
      })
    })
  }

  return (
    <div>
      <div className="flex items-center justify-between mx-6">
        <p className="text-gray-700 font-bold">Inpainting dilation</p>
        <div className="flex items-center">
          <MyButton
            disabled={mutating}
            isLoading={mutating}
            size="small"
            variant="transparent"
            onClick={() => handleMutate()}
          >
            <FiRefreshCcw className="mr-3" />
            Rerender
          </MyButton>
        </div>
      </div>

      <div className={`mt-6`}>
        <div className="mx-6 grid grid-cols-2 gap-6">
          <div>
            <p className="text-gray-600 mb-2">Kernel</p>
            <Slider
              min={1}
              max={30}
              value={extraOptions?.inpaintingDilation?.kernel || 1}
              color="secondary"
              valueLabelDisplay="auto"
              onChange={(e, val) => {
                if (typeof val === "number" && (val % 2 !== 0 || val == 0)) {
                  handleExtraOptions({
                    inpaintingDilation: {
                      kernel: val,
                      iterations:
                        extraOptions?.inpaintingDilation?.iterations || 0
                    }
                  })
                }
              }}
            />
          </div>
          <div>
            <p className="text-gray-600 mb-2">Iterations</p>
            <Slider
              value={extraOptions?.inpaintingDilation?.iterations || 1}
              min={1}
              max={30}
              color="secondary"
              valueLabelDisplay="auto"
              onChange={(e, val) => {
                if (typeof val === "number") {
                  handleExtraOptions({
                    inpaintingDilation: {
                      kernel: extraOptions?.inpaintingDilation?.kernel || 0,
                      iterations: val
                    }
                  })
                }
              }}
            />
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="mt-24">
          <MyLoader size={36} />
        </div>
      )}

      <button
        onClick={() => handleResetExtraOptions()}
        className="text-primary-400 mt-3 hover:text-primary-600 transition-all duration-100 ease-in-out mx-6"
      >
        Reset
      </button>
    </div>
  )
}
