import urls from "../config.json"
import { ServerError } from "../types"
import {} from "../types/parser"
import {
  TranslatorJob,
  TranslatorJobContainerList,
  TranslatorJobContainerRequestDto,
  TranslatorJobContainerResponseDto,
  TranslatorJobRequestDto,
  TranslatorMangaJobList
} from "../types/translator"
import { HttpMethod } from "../utils/enums"
import { auth } from "../utils/firebase"
const baseUrl = urls["service-aggregation"]

//  ======================================================================
//  User Service
//  ======================================================================

const headers = {
  "Content-Type": "application/json"
}

export const TranslatorJobsService = {
  async getById(id: string, _: string): Promise<TranslatorJob> {
    const idToken = await auth.currentUser?.getIdToken()

    return fetch(baseUrl + "/translation-job/" + id, {
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      const json = await res.json()
      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }
      return json
    })
  },

  async getByIds(ids: string[]): Promise<Omit<TranslatorJob, "assets">[]> {
    const idToken = await auth.currentUser?.getIdToken()

    return fetch(baseUrl + "/translation-job/by-ids?ids=" + ids.join(`&ids=`), {
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      const json = await res.json()
      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }
      return json
    })
  },

  // Entry point to get list of Translation Manga Job List that contains Translation Job Containers
  async getTranslatorMangaJobsList(_: string): Promise<TranslatorMangaJobList> {
    const idToken = await auth.currentUser?.getIdToken()
    return fetch(baseUrl + "/translation-manga-job/list", {
      method: "POST",
      body: JSON.stringify({
        page: 1,
        pageSize: 20,
        sorting: "DESC"
      }),
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      const json = await res.json()

      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }

      return json
    })
  },

  async create(
    body: TranslatorJobContainerRequestDto,
    _: string
  ): Promise<string> {
    const idToken = await auth.currentUser?.getIdToken()
    return fetch(baseUrl + "/translation-job-container", {
      body: JSON.stringify(body),
      method: HttpMethod.POST,
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      if (!res.ok) {
        const json = await res.json()
        throw new Error((json as ServerError).message)
      }
      return await res.text()
    })
  },

  async patch(
    data: TranslatorJobRequestDto,
    id: string,
    _?: string
  ): Promise<string> {
    const idToken = await auth.currentUser?.getIdToken()
    return fetch(baseUrl + `/translation-job/manual/${id}`, {
      method: HttpMethod.PATCH,
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      },
      body: JSON.stringify(data)
    }).then(async (res) => {
      if (!res.ok) {
        const json = await res.json()
        throw new Error((json as ServerError).message)
      }

      return await res.text()
    })
  }
}
