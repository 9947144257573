import { Divider, Typography, useTheme } from "@mui/material"
import { FirebaseError } from "firebase/app"
import { useMutation } from "react-query"
import { useAuth } from "../../context/auth"
import { theme } from "../../utils/theme"
import { MyButton } from "../Button"

export default function Social() {
  const { signInWithFB, signInWithGoogle } = useAuth()

  const { mutate, error } = useMutation<unknown, FirebaseError>({
    mutationFn: () => signInWithFB()
  })

  const { mutate: loginGoogle, error: googleLoginError } = useMutation<
    unknown,
    FirebaseError
  >({
    mutationFn: () => signInWithGoogle()
  })

  const theme = useTheme()

  return (
    <div className="my-4">
      <div className="flex items-center justify-between">
        <Divider sx={{ flexGrow: 1 }} />
        <span className="mx-3">Або</span>
        <Divider sx={{ flexGrow: 1 }} />
      </div>
      <div className="grid grid-cols-2 gap-2 mt-4">
        <MyButton
          variant="transparent"
          sx={{
            border: "1px solid",
            borderColor: theme.palette.divider
          }}
          fullWidth
          onClick={() => loginGoogle()}
          className="col-span-12"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-3"
          >
            <path
              d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
              fill="#FFC107"
            />
            <path
              d="M3.153 7.3455L6.4385 9.755C7.3275 7.554 9.4805 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C8.159 2 4.828 4.1685 3.153 7.3455Z"
              fill="#FF3D00"
            />
            <path
              d="M12 22.0001C14.583 22.0001 16.93 21.0116 18.7045 19.4041L15.6095 16.7851C14.5718 17.5743 13.3037 18.0011 12 18.0001C9.399 18.0001 7.1905 16.3416 6.3585 14.0271L3.0975 16.5396C4.7525 19.7781 8.1135 22.0001 12 22.0001Z"
              fill="#4CAF50"
            />
            <path
              d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
              fill="#1976D2"
            />
          </svg>
          Google
        </MyButton>
      </div>
      {(error || googleLoginError) && (
        <Typography className="mt-3" color="error.main">
          Помилка: {error?.code} {googleLoginError?.code}
        </Typography>
      )}
    </div>
  )
}
