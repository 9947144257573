import React, { useMemo } from "react"
import { FiLayers } from "react-icons/fi"
import {
  TranslatorEditorResourceType,
  useTranslatorEditorContext
} from "../../context/translator-editor"

type Props = {
  title: string
  type: TranslatorEditorResourceType
}

export default function ResourceLayer({ title, type }: Props) {
  const { handleResourceType, resourceType } = useTranslatorEditorContext()
  const isActive = resourceType === type

  const classes = [
    `w-full group flex items-center px-6 py-3 hover:text-primary-500 hover:bg-primary-25 transition-all duration-100 ease-in-out text-gray-600`
  ]

  if (isActive) classes.push("!text-primary-500 !bg-primary-25")

  return (
    <button
      onClick={() => handleResourceType(type)}
      className={classes.join(" ")}
    >
      <FiLayers
        size={18}
        className={`mr-3 text-gray-400 group-hover:text-primary-500 transition-all duration-100 ease-in-out${
          isActive ? ` !text-primary-500` : ``
        }`}
      />
      {title}
    </button>
  )
}
