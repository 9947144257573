// ======================================================
// Tokens
// ======================================================

const colors = require("tailwindcss/colors")

const GrayToken = {
  25: "#F8F8F9",
  50: "#F1F1F4",
  100: "#E3E4E8",
  200: "#C6C8D2",
  300: "#AAADBB",
  400: "#8E91A4",
  500: "#74768",
  600: "#5B5E71",
  700: "#444755",
  800: "#2D2F39",
  900: "#17181C"
}

const PrimaryToken = {
  25: "#F4F8FD",
  50: "#EAF1FB",
  100: "#D5E2F6",
  200: "#AAC5EE",
  300: "#80A8E5",
  400: "#558BDD",
  500: "#3374D6",
  600: "#2258AA",
  700: "#1A427F",
  800: "#112C55",
  900: "#09162A"
}

const GreenToken = colors.emerald
const IndigoToken = colors.indigo
const WarningToken = colors.amber

const ErrorToken = {
  50: "#FDE8E8",
  100: "#FBD0D0",
  200: "#F7A1A1",
  300: "#F37272",
  400: "#EF4343",
  500: "#EF4444",
  600: "#BC1010",
  700: "#8D0C0C",
  800: "#5E0808",
  900: "#2F0404"
}

// ======================================================
// Typography
// ======================================================

const SystemFonts = [
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Helvetica",
  "Arial",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol"
]

const FontFamily = {
  regular: ["GTWalsheimPro", ...SystemFonts],
  medium: ["GTWalsheimPro-Medium", ...SystemFonts],
  bold: ["GTWalsheimPro-Bold", ...SystemFonts],
  extrabold: ["GTWalsheimPro-ExtraBold", ...SystemFonts],
  black: ["GTWalsheimPro-Black", ...SystemFonts]
}

const FontHeaders = {
  h1: {
    fontSize: 48,
    fontFamily: FontFamily.bold.join(",")
  },
  h2: {
    fontSize: 24,
    fontFamily: FontFamily.bold.join(",")
  },
  h3: {
    fontSize: 20,
    fontFamily: FontFamily.bold.join(",")
  },
  h4: {
    fontSize: 16,
    fontFamily: FontFamily.bold.join(",")
  },
  h5: {
    fontSize: 14,
    fontFamily: FontFamily.bold.join(",")
  },
  h6: {
    fontSize: 12,
    fontFamily: FontFamily.bold.join(",")
  }
}

// ======================================================
// Utils
// ======================================================

const Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

module.exports = {
  Breakpoints,
  FontFamily,
  SystemFonts,
  ErrorToken,
  WarningToken,
  IndigoToken,
  PrimaryToken,
  GreenToken,
  GrayToken,
  FontHeaders
}
