import {getAuth} from "firebase/auth";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getFirestore} from "firebase/firestore";

export const firebaseConfig = {
    apiKey: "AIzaSyCQZspUeNCoxOIBdt15cC7itsc3bPzBca0",
    authDomain: "ridnamanga.firebaseapp.com",
    projectId: "ridnamanga",
    storageBucket: "ridnamanga.appspot.com",
    messagingSenderId: "909886733017",
    appId: "1:909886733017:web:b74784218b9548d5599cc8",
    measurementId: "G-3Q7CTB0BMH"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth()