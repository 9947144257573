export const truncate = (str: string, n: number) => {
  return str.length > n ? str.slice(0, n - 1) + "..." : str
}

export function arrayMove(
  arr: Array<any>,
  old_index: number,
  new_index: number
) {
  while (old_index < 0) {
    old_index += arr.length
  }
  while (new_index < 0) {
    new_index += arr.length
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }

  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr
}

export const toggleArrayElement = (arr: Array<any>, val: any) => {
  return arr.includes(val) ? arr.filter((el) => el !== val) : [...arr, val]
}
