import { Fade } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import {
  LazyLoadImage,
  LazyLoadImageProps
} from "react-lazy-load-image-component"
import { MyLoader } from "./Loader"

interface Props extends LazyLoadImageProps {
  wrapperStyles?: React.CSSProperties
  wrapperRef?: React.LegacyRef<HTMLDivElement>
}

interface State {
  isLoading: boolean
  error: React.SyntheticEvent<HTMLImageElement, Event> | null
}

export const LazyImageComponent: React.FC<Props> = ({
  onError,
  beforeLoad,
  afterLoad,
  wrapperStyles,
  wrapperRef,
  ...props
}) => {
  const [state, setState] = useState<State>({
    isLoading: false,
    error: null
  })

  const show = !state.isLoading && !state.error

  return (
    <div ref={wrapperRef} className="relative" style={wrapperStyles}>
      <Fade timeout={300} in={show}>
        <div>
          <LazyLoadImage
            beforeLoad={() => {
              console.log("Start Loading")
              setState({ isLoading: true, error: null })
              if (beforeLoad) beforeLoad()
            }}
            afterLoad={() => {
              console.log("Finish Loading")
              setState({ isLoading: false, error: null })
              if (beforeLoad) beforeLoad()
            }}
            onError={(error) => {
              setState({ isLoading: false, error })
            }}
            {...props}
          />
        </div>
      </Fade>
    </div>
  )
}
