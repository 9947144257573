import React from "react"
import { LazyImageComponent } from "../LazyImageComponent"
import { MyLoader } from "../Loader"
import { useTranslatorEditorContext } from "../../context/translator-editor"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { TranslatorJobContainerResponseDto } from "../../types/translator"

function TranslatorFrame() {
  //  ======================================================================
  //  State
  //  ======================================================================

  const {
    data,
    isLoading: dataIsLoading,
    resourceType,
    resourceError,
    resourceLoading,
    resourceSrc
  } = useTranslatorEditorContext()

  const { translatorJobContainerId } = useParams()
  const queryClient = useQueryClient()
  const tjContainer =
    queryClient.getQueryData<TranslatorJobContainerResponseDto>([
      "translator-job-container",
      translatorJobContainerId
    ])

  //  ======================================================================
  //  Handlers
  //  ======================================================================

  return (
    <React.Fragment>
      <div className="absolute top-0 left-0 w-full overflow-y-auto h-full bg-white">
        {/* ======================================== */}
        {/* Resource | Frame */}
        {/* ======================================== */}

        {resourceSrc && (
          <LazyImageComponent
            style={{
              width: "100%",
              height: "auto"
            }}
            wrapperStyles={{
              minHeight: 512,
              width: "100%"
            }}
            className={
              (data?.status !== "PENDING" && data?.status !== "DONE") ||
              tjContainer?.status === "DONE"
                ? `opacity-25 pointer-events-none`
                : undefined
            }
            src={resourceSrc}
          />
        )}

        {/* ======================================== */}
        {/* Error */}
        {/* ======================================== */}
        {/* 
        {data &&
          data.status === "CANCELLED" &&
          data.error &&
          data.error !== "no error" &&
          !dataIsLoading &&
          !resourceLoading && (
            <div className="h-full w-full flex items-center justify-center text-error-400">
              <div className="w-1/2 text-center">{data.error}</div>
            </div>
          )} */}

        {/* ======================================== */}
        {/* Loader */}
        {/* ======================================== */}

        {(dataIsLoading || resourceLoading) && (
          <div className="h-full w-full flex items-center justify-center">
            <MyLoader size={36} />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default TranslatorFrame
