import urls from "../config.json"
import { ServerError } from "../types"
import { ChapterJobResponseDto, MangaJobResponseDto } from "../types/parser"
import { auth } from "../utils/firebase"
const baseUrl = urls["service-aggregation"]

//  ======================================================================
//  User Service
//  ======================================================================

const headers = {
  "Content-Type": "application/json"
}

export const MangaJobsService = {
  async getById(id: string, _: string): Promise<MangaJobResponseDto[]> {
    const idToken = await auth.currentUser?.getIdToken()

    return fetch(baseUrl + "/manga-job/" + id, {
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      const json = await res.json()
      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }
      return json
    })
  },

  async getList(_: string): Promise<MangaJobResponseDto[]> {
    const idToken = await auth.currentUser?.getIdToken()

    return fetch(baseUrl + "/manga-job/list?page=1&pageSize=50&sorting=DESC", {
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      const json = await res.json()
      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }
      return json
    })
  }
}
