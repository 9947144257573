import { Divider } from "@mui/material"
import React, { useState } from "react"
import { FiLogOut } from "react-icons/fi"
import { useAuth } from "../../context/auth"
import { MyAvatar } from "../Avatar"
import { MyMenu, MyMenuItem } from "../Menu"

export default function User() {
  const { user, logout } = useAuth()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const exit = () => {
    handleClose()
    logout()
  }

  return (
    <div className="flex items-center">
      <button onClick={handleClick}>
        <MyAvatar size={36} src={user?.photoURL || ""} />
      </button>
      <MyMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: -12,
          horizontal: "right"
        }}
      >
        <MyMenuItem>{user?.displayName || user?.email}</MyMenuItem>
        <Divider className="mx-4" />
        <MyMenuItem onClick={exit}>
          <FiLogOut size={18} className="mr-4" />
          Вийти
        </MyMenuItem>
      </MyMenu>
    </div>
  )
}
