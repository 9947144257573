import { Box, IconButton } from "@mui/material"
import { SnackbarContent, CustomContentProps, useSnackbar } from "notistack"
import React, { forwardRef, useCallback } from "react"
import { FiX } from "react-icons/fi"
import { theme } from "../utils/theme"

declare module "notistack" {
  interface VariantOverrides {
    warning: false
    myCustomVariant: true
    info: {
      subtitle?: JSX.Element | string
    }
    error: {
      subtitle?: JSX.Element | string
    }
  }
}

interface Props extends CustomContentProps {
  subtitle: JSX.Element
}

export const InfoSnackbar = forwardRef<HTMLDivElement, Props>(
  ({ message, subtitle, id }, ref) => {
    const { closeSnackbar } = useSnackbar()

    const handleDismiss = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])

    return (
      <SnackbarContent style={{ maxWidth: 256 }} ref={ref} role="alert">
        <Box
          sx={{
            width: "100%",
            padding: "8px 20px",
            background: "#FFE799",
            borderRadius: "4px 4px 0px 0px",
            fontSize: 16,
            boxShadow: "0px 12px 25px rgba(73, 75, 80, 0.05)",
          }}
        >
          <div className="w-100 flex items-center justify-between">
            {message}
            <div className="ms-3">
              <IconButton onClick={handleDismiss}>
                <FiX size={16} />
              </IconButton>
            </div>
          </div>
        </Box>
        {subtitle && (
          <Box
            sx={{
              padding: "12px 20px",
              background: "#ffffff",
              borderRadius: "0px 0px 4px 4px",
              boxShadow: "0px 12px 25px rgba(73, 75, 80, 0.05)",
              width: "100%",
            }}
          >
            <p>{subtitle}</p>
          </Box>
        )}
      </SnackbarContent>
    )
  }
)

export const ErrorSnackbar = forwardRef<HTMLDivElement, Props>(
  ({ message, subtitle, id }, ref) => {
    const { closeSnackbar } = useSnackbar()
    // const [isOpen, setOpen] = useState(false)

    const handleDismiss = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])

    return (
      <SnackbarContent style={{ maxWidth: 256 }} ref={ref} role="alert">
        <Box
          sx={{
            width: "100%",
            padding: "8px 20px",
            background: theme.palette.error.main,
            borderRadius: "4px 4px 0px 0px",
            fontSize: 16,
            color: "#ffffff",
            boxShadow: "0px 12px 25px rgba(73, 75, 80, 0.05)",
          }}
        >
          <div className="w-100 flex items-center justify-between">
            {message}
            <div className="ms-3">
              <IconButton onClick={handleDismiss} sx={{ color: "#ffffff" }}>
                <FiX size={16} />
              </IconButton>
            </div>
          </div>
        </Box>
        {subtitle && (
          <Box
            sx={{
              width: "100%",
              padding: "12px 20px",
              background: "#ffffff",
              borderRadius: "0px 0px 4px 4px",
              boxShadow: "0px 12px 25px rgba(73, 75, 80, 0.05)",
            }}
          >
            <p>{subtitle}</p>
          </Box>
        )}
      </SnackbarContent>
    )
  }
)

InfoSnackbar.displayName = "InfoSnackbar"
ErrorSnackbar.displayName = "ErrorSnackbar"
