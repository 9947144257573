import { async } from "@firebase/util"
import { doc, getDoc, setDoc } from "firebase/firestore"
import urls from "../config.json"
import { ServerError } from "../types"
import { HttpMethod } from "../utils/enums"
import { auth } from "../utils/firebase"
const baseUrl = urls.resource

//  ======================================================================
//  Resource Service
//  ======================================================================

const headers = {
  "Content-Type": "application/json"
}

export const ResourceService = {
  getById(id: string): Promise<string> {
    const ms = Date.now()
    return fetch(baseUrl + `/${id}?dummy=${ms}`).then(async (res) => {
      if (!res.ok) {
        const json = await res.json()
        throw new Error((json as ServerError).message)
      }

      const blob = await res.blob()

      return URL.createObjectURL(blob)
    })
  },

  async post(formData: FormData, rscId?: string): Promise<string> {
    const idToken = await auth.currentUser?.getIdToken()
    return fetch(
      `${urls.resourcePublishing}?resourceFolder=internal&convertToWebP=true${
        rscId ? `&resourceId=${rscId}` : ``
      }`,
      {
        method: HttpMethod.POST,
        headers: {
          "x-id-token": idToken || ""
        },
        body: formData
      }
    ).then(async (res) => {
      if (!res.ok) {
        const json = await res.json()
        throw new Error((json as ServerError).message)
      }

      return await res.text()
    })
  },

  //

  async copyToPublick(rscIds: string[]): Promise<string[]> {
    const idToken = await auth.currentUser?.getIdToken()
    return Promise.all(
      rscIds.map((rscId) =>
        fetch(`${urls.resourcePublishing}/copy-to-public-resources/${rscId}`, {
          method: HttpMethod.PATCH,
          headers: {
            "x-id-token": idToken || ""
          }
        }).then(async (res) => {
          if (!res.ok) {
            const json = await res.json()
            throw new Error((json as ServerError).message)
          }

          return await res.text()
        })
      )
    )
  }
}
