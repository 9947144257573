import urls from "../config.json"
import { PaginationParam, ServerError } from "../types"
import {
  ChapterJobRequestDto,
  ChapterJobResponseDto,
  MangaJobRequestDto
} from "../types/parser"
import { TranslatorJobContainerRequestDto } from "../types/translator"
import { HttpMethod } from "../utils/enums"
import { auth } from "../utils/firebase"
const baseUrl = urls["service-aggregation"]

//  ======================================================================
//  User Service
//  ======================================================================

const headers = {
  "Content-Type": "application/json"
}

export const ChapterJobsService = {
  async getById(id: string, _: string): Promise<ChapterJobResponseDto> {
    const idToken = await auth.currentUser?.getIdToken()

    return fetch(baseUrl + "/chapter-job/" + id, {
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      const json = await res.json()
      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }
      return json
    })
  },

  async getList(
    mangaJobId: string,
    params: PaginationParam,
    _?: string
  ): Promise<ChapterJobResponseDto[]> {
    const idToken = await auth.currentUser?.getIdToken()

    return fetch(
      `${baseUrl}/chapter-job/by-manga-job/${mangaJobId}?page=${params.page}&pageSize=${params.pageSize}&sorting=DESC`,
      {
        headers: {
          ...headers,
          "x-id-token": idToken || ""
        }
      }
    ).then(async (res) => {
      const json = await res.json()
      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }
      return json
    })
  },

  async create(body: MangaJobRequestDto, _: string) {
    const idToken = await auth.currentUser?.getIdToken()

    return fetch(baseUrl + "/parser", {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      const json = await res.json()
      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }
      return json
    })
  },

  async patch(
    body: Partial<ChapterJobRequestDto>,
    chapterJoId: string,
    _: string
  ) {
    const idToken = await auth.currentUser?.getIdToken()

    return fetch(baseUrl + "/parser/" + chapterJoId, {
      method: HttpMethod.PATCH,
      body: JSON.stringify(body),
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      const json = await res.json()
      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }
      return json
    })
  }
}
