import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material"
import React, { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { FiX } from "react-icons/fi"
import { MangaSrcTypes, MANGA_SRC_NAMES } from "../../utils/enums"
import { MyButton, MyIconButton } from "../Button"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { MyTextFieldC } from "../MyTextfield"
import { MySelectC } from "../Select"
import { MyMenuItem } from "../Menu"
import { MySliderC } from "../Slider"
import { useMutation, useQuery } from "react-query"
import { MangaResponseDto } from "../../types"
import { MangaService } from "../../services/MangaService"
import { MyLoader } from "../Loader"
import { MangaPreviewPopup, MangaPreviewTooltip } from "../manga/MangaPreview"
import { useDebounce } from "../../utils/hooks"
import { ChapterJobsService } from "../../services/ChapterJobsService"
import { useAuth } from "../../context/auth"
import { enqueueSnackbar } from "notistack"
import { ChapterJobResponseDto } from "../../types/parser"

interface Fields {
  mangaId: string
  mangaUrl: string
  mangaSrcName: MangaSrcTypes
  volume: number
  chapterNum: number
  chapterUrl: string
  lazyLoadingTimeoutSeconds: number
  scrollPauseTimeSeconds: number
}

const FieldsSchema = yup.object({
  mangaId: yup.string().required(),
  mangaUrl: yup.string().url().required(),
  mangaSrcName: yup.string().required(),
  volume: yup.number().min(0).required(),
  chapterNum: yup.number().min(0).required(),
  chapterUrl: yup.string().url().required(),
  lazyLoadingTimeoutSeconds: yup.number().required(),
  scrollPauseTimeSeconds: yup.number().required()
})

interface Props {
  open: boolean
  handleClose: () => void
  chapter?: ChapterJobResponseDto
}

const defaultValues = {}

export default function MangaJobCreate({ open, handleClose, chapter }: Props) {
  //  ======================================================================
  //  State
  //  ======================================================================

  const { user } = useAuth()

  const defaultValues = useMemo<Partial<Fields>>(
    () => ({
      mangaSrcName: "CHAP_MANGANATO",
      volume: 1,
      chapterNum: 1,
      lazyLoadingTimeoutSeconds: 60,
      scrollPauseTimeSeconds: 5
    }),
    []
  )

  const { control, handleSubmit, watch, reset } = useForm<Fields>({
    resolver: yupResolver(FieldsSchema),
    defaultValues,
    mode: "onBlur"
  })

  const mangaId = watch("mangaId")
  const mangaIdDebounced = useDebounce(mangaId, 1000)

  //  ======================================================================
  //  Queries
  //  ======================================================================

  const { data, isLoading, error } = useQuery<MangaResponseDto, Error>({
    queryFn: () => MangaService.getById(mangaIdDebounced as string),
    queryKey: ["manga", mangaIdDebounced],
    enabled: !!mangaIdDebounced
  })

  //  ======================================================================
  //  Mutations
  //  ======================================================================

  const {
    isLoading: isMutating,
    error: mutatingError,
    mutate
  } = useMutation<any, Error, Fields>({
    mutationFn: (data) =>
      ChapterJobsService.create(
        {
          mangaId: data.mangaId,
          mangaSrcName: data.mangaSrcName,
          mangaUrl: data.mangaUrl,
          chapterJob: {
            title: "title",
            volume: data.volume,
            chapterNum: data.chapterNum,
            chapterUrl: data.chapterUrl,
            options: {
              lazyLoadingTimeoutSeconds: data.lazyLoadingTimeoutSeconds,
              scrollPauseTimeSeconds: data.scrollPauseTimeSeconds
            }
          }
        },
        user?.idToken as string
      ),
    onSuccess: (data, vars) => {
      enqueueSnackbar("chapterJob successfull created", {
        variant: "info"
      })
    },
    onError: () => {
      enqueueSnackbar("Error", {
        variant: "error",
        subtitle: <p>{mutatingError?.message}</p>
      })
    }
  })

  //  ======================================================================
  //  Handlers
  //  ======================================================================

  const onSubmit = (data: Fields) => mutate(data)

  //  ======================================================================
  //  Effects
  //  ======================================================================

  // set default data with last container chapter job
  useEffect(() => {
    if (chapter) {
      reset({
        ...defaultValues,
        mangaId: chapter.mangaId,
        chapterUrl: chapter.chapterUrl,
        chapterNum: chapter.chapterNum + 1,
        mangaSrcName: chapter.srcName
      })
    }
  }, [chapter])

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={() => handleClose()}
        open={open}
        scroll="body"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle className="flex justify-between items-center p-6">
            <p className="text-md text-gray-700 font-medium">
              Створити новий контейнер
            </p>
            <MyIconButton onClick={handleClose}>
              <FiX size={18} />
            </MyIconButton>
          </DialogTitle>
          <DialogContent className="px-6 py-0">
            <div className="grid grid-cols-2 gap-x-3 gap-y-6">
              {mangaIdDebounced && (
                <div className="col-span-2">
                  {isLoading && <MyLoader size={16} />}
                  {data && (
                    <MangaPreviewTooltip
                      placement="right-start"
                      enterDelay={650}
                      enterNextDelay={650}
                      leaveDelay={0}
                      title={<MangaPreviewPopup mangaId={data.id} />}
                    >
                      <a
                        href="#"
                        className="text-gray-700 hover:text-gray-600 hover:underline"
                      >
                        {data.title}
                      </a>
                    </MangaPreviewTooltip>
                  )}
                  {error && <p className="text-error-500">{error.message}</p>}
                </div>
              )}
              <div>
                <p className="mb-2 text-gray-600 text-sm">
                  Ідентифікатор коміксу
                </p>
                <MyTextFieldC
                  name="mangaId"
                  control={control}
                  muiProps={{
                    placeholder: "manga id",
                    fullWidth: true
                  }}
                />
              </div>
              <div>
                <p className="mb-2 text-gray-600 text-sm">Джерело</p>
                <MySelectC
                  muiProps={{ fullWidth: true }}
                  control={control}
                  name="mangaSrcName"
                >
                  {MANGA_SRC_NAMES.map((value) => (
                    <MyMenuItem key={value} value={value}>
                      {value}
                    </MyMenuItem>
                  ))}
                </MySelectC>
              </div>
              <div className="col-span-2">
                <p className="mb-2 text-gray-600 text-sm">
                  Джерело коміксу ( Manga Url )
                </p>
                <MyTextFieldC
                  name="mangaUrl"
                  control={control}
                  muiProps={{
                    placeholder: "https://(mangabob.net / m.fanfox.net)",
                    fullWidth: true
                  }}
                />
              </div>
              <div className="col-span-2">
                <p className="mb-2 text-gray-600 text-sm">
                  Джерело Епізоду ( Chapter Url )
                </p>
                <MyTextFieldC
                  name="chapterUrl"
                  control={control}
                  muiProps={{
                    placeholder: "https://(mangabob.net / m.fanfox.net)",
                    fullWidth: true
                  }}
                />
              </div>
              <div>
                <p className="mb-2 text-gray-600 text-sm">Том</p>
                <MyTextFieldC
                  name="volume"
                  control={control}
                  muiProps={{
                    placeholder: "Введіть число",
                    fullWidth: true,
                    disabled: true
                  }}
                />
              </div>
              <div>
                <p className="mb-2 text-gray-600 text-sm">Епізод</p>
                <MyTextFieldC
                  name="chapterNum"
                  control={control}
                  muiProps={{
                    placeholder: "Введіть число",
                    fullWidth: true,
                    type: "number"
                  }}
                />
              </div>
              <div>
                <p className="mb-2 text-gray-600 text-sm">Lazy Loading</p>
                <MySliderC
                  name="lazyLoadingTimeoutSeconds"
                  control={control}
                  muiProps={{
                    step: 1,
                    min: 0,
                    max: 360,
                    color: "secondary",
                    valueLabelDisplay: "auto"
                  }}
                />
              </div>
              <div>
                <p className="mb-2 text-gray-600 text-sm">Scroll Pause</p>
                <MySliderC
                  name="scrollPauseTimeSeconds"
                  control={control}
                  muiProps={{
                    step: 1,
                    min: 0,
                    max: 60,
                    color: "secondary",
                    valueLabelDisplay: "auto"
                  }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions className="p-6">
            <MyButton onClick={handleClose} size="small" variant="colorless">
              Відміна
            </MyButton>
            <MyButton size="small" type="submit">
              Підтвердити
            </MyButton>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}
