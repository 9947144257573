import { isError } from "lodash"
import { useState } from "react"
import { FiPlus } from "react-icons/fi"
import { useInfiniteQuery, useQuery } from "react-query"
import { useAuth } from "../../context/auth"
import { ChapterJobsService } from "../../services/ChapterJobsService"
import { ChapterJobResponseDto } from "../../types/parser"
import { MyLoader } from "../Loader"
import {
  MyTable,
  MyTableBody,
  MyTableCell,
  MyTableHead,
  MyTableRow
} from "../Table"
import ChapterJob from "./ChapterJob"
import MangaJobCreate from "./MangaJobCreate"

interface Props {
  mangaJobId: string
}

const ChapterJobList: React.FC<Props> = ({ mangaJobId }) => {
  //  ======================================================================
  //  State
  //  ======================================================================

  const { user } = useAuth()
  const [modalOpen, setModalOpen] = useState(false)

  //  ======================================================================
  //  Queries
  //  ======================================================================

  const { data, isLoading, error } = useQuery<ChapterJobResponseDto[], Error>({
    queryFn: () =>
      ChapterJobsService.getList(mangaJobId, {
        page: 1,
        pageSize: 50
      }),
    enabled: !!user,
    queryKey: ["chapter-jobs", mangaJobId],
    refetchInterval: 1000 * 60
  })

  //  ======================================================================
  //  Handlers
  //  ======================================================================

  const handleClose = () => setModalOpen(false)

  return (
    <MyTable>
      <MyTableHead>
        <MyTableRow>
          <MyTableCell>Статус</MyTableCell>
          <MyTableCell>Топ / Епізод</MyTableCell>
          <MyTableCell>Джерело</MyTableCell>
          <MyTableCell>Останні зміни</MyTableCell>
          <MyTableCell>Переклад</MyTableCell>
          <MyTableCell align="right"></MyTableCell>
        </MyTableRow>
      </MyTableHead>
      <MyTableBody>
        <MyTableRow>
          <MyTableCell
            className="cursor-pointer text-primary-500 hover:bg-primary-25 transition-all duration-150 p-0"
            colSpan={6}
          >
            <button
              onClick={() => setModalOpen(true)}
              className="w-full flex items-center px-4 py-3"
            >
              <FiPlus size={16} className="mr-3" />
              Створити нову задачу
            </button>
            <MangaJobCreate
              open={modalOpen}
              handleClose={() => setModalOpen(false)}
              chapter={data ? data[0] : undefined}
            />
          </MyTableCell>
        </MyTableRow>
        {isLoading && (
          <MyTableCell className="px-4 py-3" colSpan={6}>
            <div className="w-full">
              <MyLoader size={24} />
            </div>
          </MyTableCell>
        )}
        {error && (
          <MyTableCell
            className="px-4 py-3 text-center text-error-500"
            align="center"
            colSpan={6}
          >
            {error.message}
          </MyTableCell>
        )}
        {data && data.map((item) => <ChapterJob key={item.id} {...item} />)}
      </MyTableBody>
    </MyTable>
  )
}

export default ChapterJobList
