import urls from "../config.json"
import {
  FilterParam,
  MangaCounters,
  MangaResponseDto,
  MangaShortRequestDto,
  ServerError,
  SortParam
} from "../types"
import { HttpMethod } from "../utils/enums"
import { auth } from "../utils/firebase"
const baseUrl = urls.entryPoint

//  ======================================================================
//  Manga Service
//  ======================================================================

const headers = {
  "Content-Type": "application/json"
}

export const MangaService = {
  getList(opt: {
    sort?: SortParam
    filters?: FilterParam
    page?: number
    pageSize?: number
  }) {
    return fetch(baseUrl + "/v2/manga/cursor-list", {
      method: HttpMethod.POST,
      headers,
      body: JSON.stringify({
        sort: opt.sort,
        filters: opt.filters,
        page: opt.page || 1,
        pageSize: opt.pageSize || 30
      })
    }).then(async (res) => {
      const json = await res.json()
      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }

      return json
    })
  },

  getById(mangaId: string): Promise<MangaResponseDto> {
    return fetch(baseUrl + "/manga/" + mangaId, {
      headers
    }).then(async (res) => {
      const json = await res.json()

      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }

      return json
    })
  },

  getByIds(ids: string[]): Promise<MangaResponseDto[]> {
    return fetch(
      baseUrl + `/v2/manga/ids?${ids.map((id) => "ids=" + id).join("&")}`,
      {
        headers
      }
    ).then(async (res) => {
      const json = await res.json()

      if (res.status !== 200) {
        throw new Error((json as ServerError).message)
      }

      return json
    })
  },

  patch(mangaId: string, data: MangaShortRequestDto) {
    return fetch(baseUrl + "/manga/" + mangaId, {
      method: HttpMethod.PATCH,
      headers,
      body: JSON.stringify(data)
    }).then((res) => res.text())
  },

  async create(data: MangaShortRequestDto, _: string) {
    const idToken = await auth.currentUser?.getIdToken()

    return fetch(baseUrl + "/v2/manga-private", {
      method: HttpMethod.POST,
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      },
      body: JSON.stringify(data)
    }).then((res) => res.text())
  },

  async delete(mangaId: string, _: string) {
    const idToken = await auth.currentUser?.getIdToken()

    return fetch(baseUrl + "/v2/manga-private" + mangaId, {
      method: HttpMethod.DELETE,
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then((res) => res.text())
  },

  getCounters(chapterId: string): Promise<MangaCounters> {
    return fetch(baseUrl + "/manga/counters/" + chapterId, {
      headers
    }).then((res) => res.json())
  }
}
