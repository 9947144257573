import { Divider } from "@mui/material"
import { enqueueSnackbar } from "notistack"
import React, { useState } from "react"
import {
  FiArrowRight,
  FiCheck,
  FiDownload,
  FiMenu,
  FiMoreHorizontal,
  FiRefreshCcw,
  FiTrash,
  FiUpload
} from "react-icons/fi"
import { QueryCache, useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router"
import { useTranslatorEditorContext } from "../../../context/translator-editor"
import { TranslatorJobsService } from "../../../services/TranslatorJobsService"
import {
  TranslatorJob,
  TranslatorJobContainerResponseDto,
  TranslatorJobStatus
} from "../../../types/translator"
import { GrayToken } from "../../../utils/tokens"
import { MyButton, MyIconButton } from "../../Button"
import { MyMenu, MyMenuItem } from "../../Menu"
import ResourceReplace from "../ResourceReplace"

export default function FrameActions() {
  //  ======================================================================
  //  State
  //  ======================================================================

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { resourceLoading, resourceError, resourceSrc, resourceType, data } =
    useTranslatorEditorContext()

  const { translatorJobId, translatorJobContainerId } = useParams()
  const queryClient = useQueryClient()

  const [openReplaceRsc, setOpenReplaceRsc] = useState(false)
  //  ======================================================================
  //  Mutations
  //  ======================================================================

  const {
    mutate,
    isLoading: isMutating,
    error: mutationError
  } = useMutation({
    mutationFn: (status: TranslatorJobStatus) =>
      TranslatorJobsService.patch({ status }, translatorJobId as string),
    onSuccess: () => {
      enqueueSnackbar("Статус було успішно змінено", { variant: "info" })
      queryClient.invalidateQueries({
        queryKey: ["translator-job", translatorJobId]
      })
      queryClient.invalidateQueries({
        queryKey: ["translator-jobs", translatorJobContainerId]
      })
    }
  })

  //  ===============================================s=======================
  //  Menu Handlers
  //  ======================================================================

  const handleClose = () => setAnchorEl(null)

  //  ===============================================s=======================
  //  Translator Job Handlers
  //  ======================================================================

  const handleChangeStatus = (status: TranslatorJobStatus) => {
    mutate(status)
    handleClose()
  }

  return (
    <React.Fragment>
      {/* ======================================== */}
      {/* Actions */}
      {/* ======================================== */}

      <MyIconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant="colorless"
        className="h-9 w-9"
        disabled={!resourceSrc}
      >
        <FiMoreHorizontal size={18} />
      </MyIconButton>

      {resourceSrc && (
        <React.Fragment>
          <MyMenu
            className={
              isMutating ? `pointer-events-none opacity-50` : undefined
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: -12,
              horizontal: "right"
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                background: GrayToken[800]
              }
            }}
          >
            {/* ======================================== */}
            {/* Action List */}
            {/* ======================================== */}

            <a
              href={resourceSrc}
              download={`translator_job_${data?.ordinal}_${resourceType}.png`}
            >
              <MyMenuItem>
                <div className="flex items-center justify-center rounded-lg h-9 w-9 bg-gray-700 mr-3">
                  <FiDownload size={18} />
                </div>
                Завантажити фрейм
              </MyMenuItem>
            </a>
            <MyMenuItem onClick={() => setOpenReplaceRsc(true)}>
              <div className="flex items-center justify-center rounded-lg h-9 w-9 bg-gray-700 mr-3">
                <FiUpload size={18} />
              </div>
              Замінити фрейм
            </MyMenuItem>
            <Divider className="mx-4" />
            <MyMenuItem onClick={() => handleChangeStatus("NONE")}>
              <div className="flex items-center justify-center rounded-lg h-9 w-9 bg-gray-700 mr-3">
                <FiRefreshCcw size={18} />
              </div>
              NONE Статус
            </MyMenuItem>
            <MyMenuItem onClick={() => handleChangeStatus("RE_RENDER")}>
              <div className="flex items-center justify-center rounded-lg h-9 w-9 bg-warn-400 text-gray-900 mr-3">
                <FiRefreshCcw size={18} />
              </div>
              RE_RENDER Статус
            </MyMenuItem>
            <MyMenuItem
              onClick={() => handleChangeStatus("RESTART_WITH_OPTIONS")}
            >
              <div className="flex items-center justify-center rounded-lg h-9 w-9 bg-warn-500 text-gray-900 mr-3">
                <FiRefreshCcw size={18} />
              </div>
              RESTART Статус
            </MyMenuItem>
            <MyMenuItem onClick={() => handleChangeStatus("DONE")}>
              <div className="flex items-center justify-center rounded-lg h-9 w-9 bg-green-600 mr-3">
                <FiCheck size={18} />
              </div>
              DONE Статус
            </MyMenuItem>
          </MyMenu>
        </React.Fragment>
      )}

      <ResourceReplace
        isOpen={openReplaceRsc}
        handleClose={() => setOpenReplaceRsc(false)}
      />
    </React.Fragment>
  )
}
