import { CircularProgress, CircularProgressProps } from "@mui/material"
import React from "react"

interface Props extends CircularProgressProps {
  label?: string
}
export const MyLoader: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <CircularProgress color={"primary"} size={24} {...props} />
      {props.label && (
        <p className="text-gray-400 text-sm mt-6">{props.label}</p>
      )}
    </div>
  )
}
