import { DialogContent, DialogTitle } from "@mui/material"
import { enqueueSnackbar } from "notistack"
import React, { useCallback, useEffect, useState } from "react"
import { FiX } from "react-icons/fi"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router"
import { useTranslatorEditorContext } from "../../context/translator-editor"
import { ChapterService } from "../../services/ChapterService"
import { ResourceService } from "../../services/ResourceService"
import { ChapterShortRequestDto } from "../../types"
import {
  TranslatorJob,
  TranslatorJobContainerResponseDto,
  TranslatorJobStatus
} from "../../types/translator"
import { MyButton, MyIconButton } from "../Button"
import { MyModal } from "../Modal"
import { MyTextField } from "../MyTextfield"
import config from "../../config.json"
import { TranslatorJobsService } from "../../services/TranslatorJobsService"
import { TranslatorContainerService } from "../../services/TranslatorContainerService"

type Props = {
  open: boolean
  handleClose: () => void
}

export default function TranslatorContainerPublish({
  open,
  handleClose
}: Props) {
  // ================================================
  // State
  // ================================================

  const { translatorJobContainerId } = useParams()

  const queryClient = useQueryClient()

  const [volume, setVolume] = useState<number>(0)
  const [chapterNum, setChapterNum] = useState<number>(0)

  const tjContainer =
    queryClient.getQueryData<TranslatorJobContainerResponseDto>([
      "translator-job-container",
      translatorJobContainerId
    ])

  const translatorJobList = queryClient.getQueryData<
    Omit<TranslatorJob, "assets">[]
  >(["translator-jobs", translatorJobContainerId])

  //  ======================================================================
  //  Mutations
  //  ======================================================================

  // copy resources from internal to publick folder
  const {
    isLoading: resourcesMutating,
    mutate: mutateResources,
    error: resourcesError
  } = useMutation({
    mutationFn: (ids: string[]) => ResourceService.copyToPublick(ids)
  })

  // publish new chapter
  const {
    isLoading: chapterMutating,
    mutate: publishChapter,
    error: chapterError
  } = useMutation({
    mutationFn: (body: ChapterShortRequestDto) => ChapterService.create(body)
  })

  const {
    isLoading: statusMutating,
    mutate: mutateStatus,
    error: statusError
  } = useMutation({
    mutationFn: () =>
      TranslatorContainerService.patch(translatorJobContainerId as string)
  })

  // ================================================
  // Handlers
  // ================================================

  const onVolumeChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setVolume(parseInt(e.target.value)),
    [setVolume]
  )

  const onChapterNumChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setChapterNum(parseInt(e.target.value)),
    [setChapterNum]
  )

  const handlePublish = useCallback(() => {
    if (translatorJobList) {
      mutateResources(
        translatorJobList.map((item) => item.output),
        {
          onSuccess: (data) => {
            if (tjContainer) {
              publishChapter(
                {
                  chapterNum: tjContainer.chapterNum,
                  volume: tjContainer.volume,
                  resourceIds: data.reduce(
                    (prev, curr, i) => ({ ...prev, [i.toString()]: curr }),
                    {} as { [key: string]: string }
                  ),
                  title: "title",
                  mangaId: tjContainer.translationJobMangaId
                },
                {
                  onError: (e) => {
                    enqueueSnackbar("Щось пішло не так!", {
                      variant: "error",
                      subtitle: `${(e as Error).message}`
                    })
                  },
                  onSuccess: (data) => {
                    enqueueSnackbar("Новий епізод було успішно створено!", {
                      variant: "info",
                      subtitle: (
                        <div>
                          <div className="font-bold">Посилання на епізод</div>
                          <a
                            href={`${config.official_domen}/read/${data}/${tjContainer.translationJobMangaId}`}
                          >
                            {`${config.official_domen}/read/${data}/${tjContainer.translationJobMangaId}`}
                          </a>
                        </div>
                      )
                    })
                    mutateStatus()
                  }
                }
              )
            }
          },
          onError: (e) => {
            enqueueSnackbar("Щось пішло не так!", {
              variant: "error",
              subtitle: `${(e as Error).message}`
            })
          }
        }
      )
    }
  }, [tjContainer, translatorJobList])

  // ================================================
  // Effects
  // ================================================

  useEffect(() => {
    if (tjContainer) {
      setVolume(tjContainer.volume)
      setChapterNum(tjContainer.chapterNum)
    }
  }, [tjContainer])

  return (
    <MyModal fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle className="flex justify-between items-center">
        <p className="text-[18px] font-bold">Опублікувати епізод</p>
        <MyIconButton onClick={handleClose} variant="transparent">
          <FiX size={18} />
        </MyIconButton>
      </DialogTitle>
      <DialogContent>
        <div className="grid grid-cols-2 gap-3">
          <div>
            <p className="text-gray-400 mb-2">Том</p>
            <MyTextField
              onChange={onVolumeChange}
              value={volume}
              disabled
              fullWidth
              placeholder="volume"
              type="number"
            />
          </div>
          <div>
            <p className="text-gray-400 mb-2">Номер Епізоду</p>
            <MyTextField
              onChange={onChapterNumChange}
              value={chapterNum}
              fullWidth
              placeholder="chapterNum"
              type="number"
            />
          </div>
        </div>
        <MyButton
          onClick={handlePublish}
          variant={"colorless"}
          fullWidth
          className="mt-6"
          disabled={
            resourcesMutating ||
            chapterMutating ||
            tjContainer?.status === "DONE"
          }
          isLoading={resourcesMutating || chapterMutating}
        >
          {tjContainer?.status === "DONE" ? "Опубліковано" : "Опублікувати"}
        </MyButton>
      </DialogContent>
    </MyModal>
  )
}
