import { Slider, SliderProps, styled } from "@mui/material"
import React from "react"
import { Control, Controller } from "react-hook-form"
import { PrimaryToken } from "../utils/tokens"

type Props = {
  control: Control<any>
  name: string
  muiProps?: SliderProps
}

const MySlider = styled(Slider)({
  color: PrimaryToken[400],
  "& .MuiSlider-thumb": {
    backgroundColor: PrimaryToken[400]
  }
})

export const MySliderC = ({ control, name, muiProps }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, name, ref },
        fieldState: { error }
      }) => (
        <MySlider
          defaultValue={value}
          name={name}
          onChange={onChange}
          {...muiProps}
        />
      )}
    />
  )
}
