import React, { useState } from "react"
import { FiPlus } from "react-icons/fi"
import { useQuery } from "react-query"
import { useAuth } from "../../context/auth"
import { MangaJobsService } from "../../services/MangaJobsService"
import { MangaJobResponseDto } from "../../types/parser"
import { MyLoader } from "../Loader"
import { MyTable, MyTableCell, MyTableRow } from "../Table"
import MangaJob from "./MangaJob"
import MangaJobCreate from "./MangaJobCreate"

export default function MangaJobList() {
  //  ======================================================================
  //  State
  //  ======================================================================

  const { user } = useAuth()
  const [modalOpen, setModalOpen] = useState(false)

  //  ======================================================================
  //  Queries
  //  ======================================================================

  const { data, isLoading, error } = useQuery<MangaJobResponseDto[], Error>({
    queryFn: () => MangaJobsService.getList(user?.idToken as string),
    enabled: !!user,
    queryKey: ["manga-jobs"]
  })

  //  ======================================================================
  //  Handlers
  //  ======================================================================

  const handleClose = () => setModalOpen(false)

  return (
    <MyTable className="w-full">
      <MyTableRow>
        <MyTableCell
          className="cursor-pointer text-primary-500 hover:bg-primary-25 transition-all duration-150 p-0"
          colSpan={3}
        >
          <button
            onClick={() => setModalOpen(true)}
            className="w-full flex items-center px-4 py-3"
          >
            <FiPlus size={16} className="mr-3" />
            Створити новий контейнер
          </button>
          <MangaJobCreate
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
          />
        </MyTableCell>
      </MyTableRow>
      {isLoading && (
        <MyTableCell className="px-4 py-3" colSpan={6}>
          <div className="w-full">
            <MyLoader size={24} />
          </div>
        </MyTableCell>
      )}
      {error && (
        <MyTableCell
          className="px-4 py-3 text-center text-error-500"
          align="center"
          colSpan={6}
        >
          {error.message}
        </MyTableCell>
      )}
      {data && data.map((item) => <MangaJob key={item.id} {...item} />)}
    </MyTable>
  )
}
