import { GlobalStyles } from "@mui/material"
import { PropsWithChildren } from "react"
import { Outlet, useParams } from "react-router"
import FramePlaceholder from "../components/editor/FramePlaceholder"
import Header from "../components/editor/header/Header"
import LeftSidebar from "../components/editor/LeftSidebar"
import RightSidebar from "../components/editor/RightSidebar"
import { useTranslatorEditorContext } from "../context/translator-editor"
import RequireAuth from "./RequireAuth"

const BlockWrapper: React.FC<PropsWithChildren & { className?: string }> = ({
  children,
  className
}) => {
  const classes = ["absolute top-0 left-0 w-full h-full"]
  if (className) classes.push(className)
  return <div className={classes.join(" ")}>{children}</div>
}

function TranslatorJobContainer() {
  const { translatorJobId } = useParams()

  return (
    <RequireAuth>
      <div className="min-h-screen flex flex-col">
        <Header />
        <GlobalStyles
          styles={{
            body: {
              background: "#FBFBFB !important"
            }
          }}
        />
        <div className="flex-grow grid grid-cols-12 gap-6">
          <div className="col-span-3 relative">
            <BlockWrapper className="bg-white rounded overflow-y-auto">
              <LeftSidebar />
            </BlockWrapper>
          </div>
          <div className="col-span-6 my-6 relative">
            <Outlet />
            {!translatorJobId && <FramePlaceholder />}
          </div>
          <div className="col-span-3 relative">
            <BlockWrapper className="bg-white rounded overflow-y-auto">
              <RightSidebar />
            </BlockWrapper>
          </div>
        </div>
      </div>
    </RequireAuth>
  )
}

export default TranslatorJobContainer
