import { stat } from "node:fs/promises"
import { text } from "node:stream/consumers"
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import { QueryCache, useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router"
import { json } from "stream/consumers"
import { ResourceService } from "../services/ResourceService"
import { TranslatorJobsService } from "../services/TranslatorJobsService"
import {
  TranslatorJob,
  TranslatorJobExtraOptions,
  TranslatorJobRequestDto,
  TranslatorJobScatteredLinesOptions,
  TranslatorJobSenteces,
  TranslatorJobTextBlocksOptions
} from "../types/translator"
import { useAuth } from "./auth"

interface TranslatorEditorTextFont {
  fontSize: number
  fontWeight: number
}

interface TranslatorEditorTextExclusion {
  exclude: boolean
}

type TextBlocksHandler = (val: string, i: number) => void
type TextFontsHandler = (val: TranslatorEditorTextFont, i: number) => void
type TextExclusionHandler = (i: number) => void

export type TranslatorEditorResourceType =
  | "bboxResourceId"
  | "maskFinalResourceId"
  | "inPaintedResourceId"
  | "output"
  | "scatterLinesBboxResourceId"
  | "resourceId"

interface TranslatorEditorContextState {
  textBlocks: TranslatorJobSenteces
  textFonts: TranslatorEditorTextFont[]
  textExclusion: TranslatorEditorTextExclusion[]
  extraOptions?: TranslatorJobExtraOptions
  scatteredLinesOptions?: TranslatorJobScatteredLinesOptions
  isLoading: boolean
  data?: TranslatorJob
  handleTextBlocks: TextBlocksHandler
  handleTextFonts: TextFontsHandler
  handleTextExclusion: TextExclusionHandler
  handleReset: () => void
  handleExtraOptions: (val: Partial<TranslatorJobExtraOptions>) => void
  // handleRender: (status: "RE_RENDER" | "RESTART_WITH_OPTIONS") => void
  // isMutating: boolean,
  resourceType: TranslatorEditorResourceType
  handleResourceType: (type: TranslatorEditorResourceType) => void
  resourceSrc: string | undefined
  resourceLoading: boolean
  resourceError: Error | null
  resource: string | undefined
  setScatteredLinesOptions: React.Dispatch<
    React.SetStateAction<TranslatorJobScatteredLinesOptions | undefined>
  >
  handleResetTextBlocks: () => void
  handleResetExtraOptions: () => void
  handleResetScatterLines: () => void
}

const TranslatorEditorContext =
  React.createContext<TranslatorEditorContextState>(
    {} as TranslatorEditorContextState
  )

export const useTranslatorEditorContext = () =>
  useContext(TranslatorEditorContext)

export const TranslatorEditorProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  //  ======================================================================
  //  State
  //  ======================================================================

  const { translatorJobId } = useParams()
  const { user } = useAuth()
  const [textBlocks, setTextBlocks] = useState<TranslatorJobSenteces>([])
  const [textFonts, setTextFonts] = useState<TranslatorEditorTextFont[]>([])
  const [resourceType, setResourceType] =
    useState<TranslatorEditorResourceType>("output")

  const [textExclusion, setTextExclusion] = useState<
    TranslatorEditorTextExclusion[]
  >([])

  const [extraOptions, setExtraOptions] = useState<
    TranslatorJobExtraOptions | undefined
  >()

  const [scatteredLinesOptions, setScatteredLinesOptions] = useState<
    TranslatorJobScatteredLinesOptions | undefined
  >()

  const queryClient = useQueryClient()
  const queryCache = new QueryCache()

  //  ======================================================================
  //  Queries
  //  ======================================================================

  // Get choosen translator job data
  const { data, isLoading, error, refetch } = useQuery({
    queryFn: () =>
      TranslatorJobsService.getById(
        translatorJobId as string,
        user?.idToken as string
      ),
    queryKey: ["translator-job", translatorJobId],
    enabled: !!(user?.idToken && translatorJobId),
    onSuccess: (data) => {
      if (
        data.status == "PENDING" ||
        (data.status == "DONE" && queryCache.find(["translator-job-resource"]))
      ) {
        queryClient.invalidateQueries({
          queryKey: ["translator-job-resource", translatorJobId]
        })
      }
    }
  })

  // Get Current Resource
  const resource = useMemo<undefined | string>(() => {
    if (data?.assets) {
      switch (resourceType) {
        case "output":
          return data.output
        case "resourceId":
          return data.resourceId
        default:
          return data.assets[resourceType]
      }
    }

    return
  }, [resourceType, data])

  const {
    data: img,
    isLoading: imgIsLoading,
    error: imgError
  } = useQuery<string, Error>({
    queryFn: () => ResourceService.getById(resource as string),
    enabled: !!resource,
    queryKey: ["translator-job-resource", translatorJobId, resourceType]
  })

  //  ======================================================================
  //  Handlers
  //  ======================================================================

  // Handle change text blocks values
  const handleTextBlocks: TextBlocksHandler = (val, i) => {
    setTextBlocks((textBlocks) =>
      textBlocks.map((text, index) => (index == i ? val : text))
    )
  }

  // Handle change text fonts
  const handleTextFonts: TextFontsHandler = useCallback(
    (val, i) => {
      setTextFonts((textFonts) => {
        console.log(textFonts)
        const newTextFonts = [...textFonts]
        newTextFonts[i] = val

        return newTextFonts
      })
    },
    [textFonts]
  )

  // Handle change text exclusion
  const handleTextExclusion: TextExclusionHandler = useCallback(
    (i) => {
      setTextExclusion((textExclusion) => {
        const exclude = !textExclusion[i].exclude
        const newTextExclusion = [...textExclusion]
        newTextExclusion[i] = { exclude }

        return newTextExclusion
      })
    },
    [textExclusion]
  )

  // Handle reset all changes
  const handleReset = useCallback(() => {
    if (data) {
      handleResetExtraOptions()
      handleResetScatterLines()
      handleResetTextBlocks()
    }
  }, [data])

  const handleResetExtraOptions = useCallback(() => {
    setExtraOptions(data?.extraOptions)
  }, [data])

  const handleResetTextBlocks = useCallback(() => {
    if (data?.assets) {
      setTextBlocks(data.assets?.translatedSentences)
      setTextExclusion(
        data.assets?.translatedSentences?.map((_, i) =>
          data.assets?.textBlocksOptions?.renderTextBlockExclusion.includes(i)
            ? { exclude: true }
            : { exclude: false }
        )
      )
      setTextFonts(data.assets?.textBlocksOptions?.textBlocksFonts)
    }
  }, [data])

  const handleResetScatterLines = useCallback(() => {
    if (data?.assets) {
      setScatteredLinesOptions(data.assets.scatteredLinesOptions)
    }
  }, [data])

  // Handle change extra options
  const handleExtraOptions = useCallback(
    (val: Partial<TranslatorJobExtraOptions>) => {
      if (extraOptions) {
        setExtraOptions({ ...extraOptions, ...val })
      }
    },
    [extraOptions]
  )

  // Handle change resource type to vissible
  const handleResourceType: TranslatorEditorContextState["handleResourceType"] =
    (type) => {
      setResourceType(type)
    }

  //  ======================================================================
  //  Effects
  //  ======================================================================

  useEffect(() => handleReset(), [data])

  useEffect(() => {
    let intervalId: null | NodeJS.Timer = null

    if (
      data?.status == "RESTART_WITH_OPTIONS" ||
      data?.status == "RE_RENDER" ||
      data?.status === "PROCESSING" ||
      data?.status === "NONE" ||
      data?.status === "QUEUED"
    ) {
      intervalId = setInterval(refetch, 1000 * 6.5)
    } else if (intervalId) {
      clearInterval(intervalId)
      intervalId = null
    }

    return () => {
      intervalId && clearInterval(intervalId)
    }
  }, [data])

  const value = useMemo<TranslatorEditorContextState>(
    () => ({
      textBlocks,
      textFonts,
      textExclusion,
      extraOptions,
      scatteredLinesOptions,
      isLoading,
      data,
      handleTextBlocks,
      handleTextExclusion,
      handleTextFonts,
      handleReset,
      handleExtraOptions,
      resourceType,
      handleResourceType,
      resourceError: imgError,
      resourceLoading: imgIsLoading,
      resourceSrc: img,
      resource,
      setScatteredLinesOptions,
      handleResetTextBlocks,
      handleResetExtraOptions,
      handleResetScatterLines
    }),
    [
      textBlocks,
      textFonts,
      textExclusion,
      extraOptions,
      scatteredLinesOptions,
      isLoading,
      data,
      handleTextBlocks,
      handleTextExclusion,
      handleTextFonts,
      handleReset,
      handleExtraOptions,
      resourceType,
      handleResourceType,
      img,
      imgError,
      imgIsLoading,
      resource,
      setScatteredLinesOptions,
      handleResetTextBlocks,
      handleResetExtraOptions,
      handleResetScatterLines
    ]
  )

  return (
    <TranslatorEditorContext.Provider value={value}>
      {children}
    </TranslatorEditorContext.Provider>
  )
}
