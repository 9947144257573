import React from "react"
import config from "../config.json"

export default function Docs() {
  return (
    <div className="container mx-auto my-6">
      <div className="mt-12 grid grid-cols-12 gap-6">
        <div className="col-span-5">
          <p className="font-bold text-lg">
            Створення новой задачі для парсеру
          </p>
          <p className="text-gray-400 text-[18px] mt-6">
            Щоб створити нову задачу для парсера, потрібно перейти в розділ
            "Парсер" і натиснути над таблицею кнопку "Додати"
          </p>
        </div>
        <div className="col-span-7">
          <img
            src="/imgs/parser-create-1.png"
            className="w-full h-auto border border-gray-100"
          />
        </div>
      </div>
      <div className="mt-12 grid grid-cols-12 gap-6">
        <div className="col-span-5">
          <p className="font-bold text-lg">Налаштування Парсеру</p>
          <p className="text-gray-400 text-[18px] mt-6">
            У відкритому вікні можна побачити наступні налаштування:
          </p>
          <p className="mt-6 text-gray-400 text-[18px]">
            <span className="font-bold"> MANGA ID </span>- це унікальний
            ідентифікатор коміксу, який слід брати{" "}
            <a
              className="underline text-primary-400"
              href={config.official_domen}
              target="_blank"
            >
              з каталогу нашого сайту
            </a>
          </p>
          <p className="mt-6 text-gray-400 text-[18px]">
            <span className="font-bold"> Джерело</span> - це ресурс звідки буде
            взято матеріали. Список ресурсів є заздалегідь визначеним і обирати
            можна лише зі списку
          </p>
          <p className="mt-6 text-gray-400 text-[18px]">
            <span className="font-bold">MANGA URL</span> - посилання на сторінку
            коміксу на обраному ресурсі
          </p>
          <p className="mt-6 text-gray-400 text-[18px]">
            <span className="font-bold"> CHAPTER URL</span> - посилання на
            сторінку епізоду / глави коміксу звідки і буде взято основні
            матеріали
          </p>
          <p className="mt-6 text-gray-400 text-[18px]">
            <span className="font-bold"> Volume ( Том )</span> - Том який буде
            обрано для нового епізоду ( номер тому визначається із списку
            епізодів{" "}
            <a
              className="underline text-primary-400"
              href={config.official_domen}
              target="_blank"
            >
              на нашому сайті
            </a>{" "}
            на сторінці коміксу )
          </p>
          <p className="mt-6 text-gray-400 text-[18px]">
            <span className="font-bold">Номер епізоду</span> - визначається
            аналогічно до тому
          </p>
          <p className="mt-6 text-gray-400 text-[18px]">
            <span className="font-bold">Lazy Loading</span> - Затримка при
            скролі
          </p>
          <p className="mt-6 text-gray-400 text-[18px]">
            <span className="font-bold">Scroll Pause</span> - Затримка між
            скролами
          </p>
        </div>
        <div className="col-span-7">
          <img
            src="/imgs/parser-configure.png"
            className="w-full h-auto border border-gray-100"
          />
        </div>
      </div>
      <div className="mt-12 grid grid-cols-12 gap-6">
        <div className="col-span-5">
          <p className="font-bold text-lg">Список задач парсеру</p>
          <p className="mt-6 text-gray-400 text-[18px]">
            На сторінці парсеру можна подивитися список усіх задач, вони
            фільтруються за назвою коміксу. Якщо натиснути на стрілку в кінці
            рядка, то можна буде побачити усі задачі повʼязані з обраним
            коміксом.
          </p>
        </div>
        <div className="col-span-7">
          <img
            src="/imgs/chapter-jobs.png"
            className="w-full h-auto border border-gray-100"
          />
        </div>
      </div>
    </div>
  )
}
