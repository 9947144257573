import { alpha, Menu, MenuItem, styled } from "@mui/material"
import { GrayToken } from "../utils/tokens"

export const MyMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 12,
    boxShadow: "0px 12px 25px rgba(73, 75, 80, 0.05)"
  }
}))

export const MyMenuItem = styled(MenuItem)(({ theme }) => ({
  // color:
  //   theme.palette.mode == "light"
  //     ? theme.palette2.text[600]
  //     : alpha(theme.palette2.text[50], 0.75),
  padding: "10px 16px",
  fontSize: 14,
  "&:hover": {
    background: theme.palette.mode === "dark" ? GrayToken[700] : GrayToken[25]
  },
  "&.Mui-selected": {
    background:
      theme.palette.mode == "light"
        ? alpha(theme.palette.primary.main, 0.075)
        : theme.palette.primary.main,
    // color: theme.palette2.text[900],
    "&:hover": {
      background:
        theme.palette.mode === "dark"
          ? theme.palette.primary.main
          : alpha(theme.palette.primary.main, 0.075)
    }
  },
  "&.Mui-disabled": {
    background: "transparent"
  }
}))
