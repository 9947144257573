import React, { useEffect, useMemo } from "react"
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements
} from "react-router-dom"
import Root from "./containers/Root"
import Login from "./containers/Login"
import { QueryClient, QueryClientProvider } from "react-query"
import { SnackbarProvider } from "notistack"
import { ErrorSnackbar, InfoSnackbar } from "./components/Snackbar"
import { theme } from "./utils/theme"
import { ThemeProvider } from "@mui/material"
import { AuthContextProvider } from "./context/auth"
import Page404 from "./containers/Page404"
import CTAPage from "./containers/CTAPage"
import Parser from "./containers/Parser"
import ChapterJobRSC from "./containers/ChapterJobRSC"
import TranslatorEditor from "./containers/Editor"
import TranslatorFrame from "./components/editor/Frame"
import { TranslatorEditorProvider } from "./context/translator-editor"
import "moment/locale/uk"
import moment from "moment"
import Docs from "./containers/Docs"

moment.locale("uk")

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path="/" element={<Root />} errorElement={<Page404 />}>
      <Route index element={<CTAPage />} />
      <Route path="parser">
        <Route index element={<Parser />} />
        <Route path="rsc/:chapterJobId" element={<ChapterJobRSC />} />
      </Route>
      <Route path="docs" element={<Docs />} />
    </Route>,
    <Route path="editor">
      <Route
        path=":translatorJobContainerId"
        element={
          <TranslatorEditorProvider>
            <TranslatorEditor />
          </TranslatorEditorProvider>
        }
      >
        <Route element={<TranslatorFrame />} path=":translatorJobId" />
      </Route>
    </Route>,
    <Route path="login" element={<Login />} />
  ])
)

function App() {
  //  ======================================================================
  //  React Query Config
  //  ======================================================================

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 30
        }
      }
    })
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <SnackbarProvider
            Components={{
              info: InfoSnackbar,
              error: ErrorSnackbar
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "top"
            }}
          >
            <RouterProvider router={router} />
          </SnackbarProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
