import { alpha, Box, SxProps, Theme } from "@mui/material"
import { DropzoneOptions, useDropzone } from "react-dropzone"
import { FiUpload } from "react-icons/fi"
import { theme } from "../utils/theme"

interface Props {
  options: DropzoneOptions
  title?: string
}

export const MyUploader: React.FC<Props> = ({ options, title }) => {
  //  ======================================================================
  //  State
  //  ======================================================================

  const { getRootProps, getInputProps, isDragActive } = useDropzone(options)

  return (
    <div
      {...getRootProps()}
      className={`px-4 py-12 flex items-center flex-col border
         hover:border-primary-300 text-gray-400 cursor-pointer rounded-lg transition-all duration-100${
           isDragActive ? `  border-primary-300` : ` border-gray-100`
         }`}
    >
      <input {...getInputProps()} />
      {isDragActive
        ? "Дропніть файл в цьому місці"
        : title || "Натисніть, або перетягніть файл"}
      <FiUpload className="mt-3" size={24} />
    </div>
  )
}
