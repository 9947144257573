import React from "react"
import { FiRefreshCcw } from "react-icons/fi"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router"
import { useTranslatorEditorContext } from "../../context/translator-editor"
import { TranslatorJobsService } from "../../services/TranslatorJobsService"
import { TranslatorJobRequestDto } from "../../types/translator"
import { MyButton } from "../Button"
import { MyLoader } from "../Loader"
import TextLayer from "./TextLayer"

export default function TextLayers() {
  // =================================================
  // State
  // =================================================

  const {
    textBlocks,
    textExclusion,
    textFonts,
    data,
    isLoading,
    extraOptions,
    handleResetTextBlocks
  } = useTranslatorEditorContext()
  const { translatorJobId, translatorJobContainerId } = useParams()
  const queryClient = useQueryClient()

  // =================================================
  // Mutations
  // =================================================

  const {
    isLoading: mutating,
    error,
    mutate
  } = useMutation({
    mutationFn: (data: TranslatorJobRequestDto) =>
      TranslatorJobsService.patch(data, translatorJobId as string),
    onSuccess: (_, vars) => {
      queryClient.setQueryData(["translator-job", translatorJobId], {
        ...data,
        assets: {
          ...data?.assets,
          ...(vars.translatedSentences && {
            translatedSentences: vars.translatedSentences
          }),
          ...(vars.textBlocksOptions && {
            textBlocksOptions: vars.textBlocksOptions
          })
        },
        status: vars.status
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["translator-jobs", translatorJobContainerId]
      })
    }
  })

  const handleMutate = () => {
    mutate({
      textBlocksOptions: {
        considerOptions: true,
        renderTextBlockExclusion: textExclusion.reduce(
          (acum, text, i) => (text.exclude ? [...acum, i] : acum),
          [] as number[]
        ),
        textBlocksFonts: textFonts
      },
      translatedSentences: textBlocks,
      status: "RE_RENDER",
      ...(extraOptions?.inpaintingDilation && {
        extraOptions: {
          considerOptions: true,
          inpaintingDilation: {
            kernel: extraOptions?.inpaintingDilation?.kernel || 1,
            iterations: extraOptions?.inpaintingDilation?.iterations || 0
          }
        }
      })
    })
  }

  return (
    <div>
      <div className="flex items-center justify-between mx-6">
        <p className="text-gray-700 font-bold">Текстові блоки</p>
        <div className="flex items-center">
          <MyButton
            disabled={mutating}
            isLoading={mutating}
            size="small"
            variant="transparent"
            onClick={() => handleMutate()}
          >
            <FiRefreshCcw className="mr-3" />
            Rerender
          </MyButton>
        </div>
      </div>

      {!isLoading && textBlocks && textBlocks.length !== 0 && (
        <div className={`mt-3`}>
          {textBlocks.map((_, i) => (
            <TextLayer index={i} key={i} />
          ))}
        </div>
      )}

      {isLoading && (
        <div className="my-12">
          <MyLoader size={36} />
        </div>
      )}

      <button
        onClick={() => handleResetTextBlocks()}
        className="text-primary-400 hover:text-primary-600 transition-all duration-100 ease-in-out mx-6 mt-3"
      >
        Reset
      </button>
    </div>
  )
}
