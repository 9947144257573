import { doc, getDoc, setDoc } from "firebase/firestore"
import urls from "../config.json"
import { UserModel } from "../types"
import { HttpMethod } from "../utils/enums"
import { db } from "../utils/firebase"
const baseUrl = urls.entryPoint

//  ======================================================================
//  User Service
//  ======================================================================

const headers = {
  "Content-Type": "application/json"
}

export const UserService = {
  getById(uid: string) {
    return getDoc(doc(db, "users", uid))
  },

  create(user: UserModel) {
    return setDoc(doc(db, "users", user.uid), user as UserModel).catch((e) => {
      throw new Error(e)
    })
  }
}
