import { Link } from "react-router-dom"

export default function Page404() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <div className="w-1/4 text-center">
        <p className="text-xl font-bold">ЙОЙ!</p>
        <p className="my-3 text-gray-400">
          Нажаль за цією адресою сторінку не знайдено
        </p>
        <Link
          to="/"
          className="text-primary-500 font-medium hover:text-primary-600 underline"
        >
          На головну
        </Link>
      </div>
    </div>
  )
}
