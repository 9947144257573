import { Select, ThemeProvider } from "@mui/material"
import React, { useState } from "react"
import { FiArrowLeft } from "react-icons/fi"
import { useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router"
import { MangaService } from "../../../services/MangaService"
import { TranslatorContainerService } from "../../../services/TranslatorContainerService"
import { MangaResponseDto } from "../../../types"
import { darkTheme } from "../../../utils/theme"
import { MyButton } from "../../Button"
import { MangaPreview } from "../../manga/MangaPreview"
import config from "../../../config.json"
import { truncate } from "lodash"
import TranslationJobList from "./TranslationJobList"
import FrameActions from "./FrameActions"
import { MyLoader } from "../../Loader"
import TranslatorContainerPublish from "../PublishModal"

export default function Header() {
  //  ======================================================================
  //  State
  //  ======================================================================

  const { translatorJobContainerId } = useParams()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  //  ======================================================================
  //  Queries
  //  ======================================================================

  const { data: tjContainer, isLoading: tjContainerLoading } = useQuery({
    queryFn: () =>
      TranslatorContainerService.getById(translatorJobContainerId as string),
    queryKey: ["translator-job-container", translatorJobContainerId]
  })

  const {
    data: manga,
    isLoading: mangaLoading,
    error
  } = useQuery<MangaResponseDto, Error>({
    queryFn: () =>
      MangaService.getById(tjContainer?.translationJobMangaId as string),
    queryKey: ["manga", tjContainer?.translationJobMangaId],
    enabled: !!tjContainer?.translationJobMangaId
  })

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="h-14 bg-gray-800 px-6 grid grid-cols-12 gap-6 text-gray-25">
        <div className="flex items-center col-span-4">
          <MyButton
            onClick={() => navigate(`/parser`)}
            size="small"
            variant="colorless"
          >
            <FiArrowLeft size={16} className="mr-3" />
            Назад
          </MyButton>
          <div className="ml-6 flex items-center">
            {tjContainer && manga && (
              <>
                <MangaPreview mangaId={manga.id}>
                  <a
                    target="_blank"
                    href={`${config.official_domen}/book/${manga.id}`}
                    className="text-gray-400 transition-all duration-150 hover:text-gray-50 hover:underline"
                  >
                    {truncate(manga.title, { length: 28 })}
                  </a>
                </MangaPreview>
                <span className="mx-2 text-gray-400 text-sm">-</span>
                <span className="text-gray-400 text-sm">
                  {tjContainer.volume} / {tjContainer.chapterNum}
                </span>
              </>
            )}
            {mangaLoading && <MyLoader size={16} />}
          </div>
        </div>
        <div className="flex items-center justify-center col-span-4">
          {tjContainer && <TranslationJobList tjContainer={tjContainer} />}
        </div>
        <div className="flex items-center justify-end col-span-4">
          <FrameActions />
          <MyButton
            onClick={() => setOpen(true)}
            size="small"
            className="ml-2 h-9"
          >
            Опублікувати
          </MyButton>
          <TranslatorContainerPublish
            open={open}
            handleClose={() => setOpen(false)}
          />
        </div>
      </div>
    </ThemeProvider>
  )
}
