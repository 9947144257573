import urls from "../config.json"
import {
  ChapterShortRequestDto,
  MangaCounters,
  ServerError,
  SortParam
} from "../types"
import { HttpMethod } from "../utils/enums"
import { auth } from "../utils/firebase"
const baseUrl = urls.entryPoint

//  ======================================================================
//  Chapter Service
//  ======================================================================

const headers = {
  "Content-Type": "application/json"
}

export const ChapterService = {
  getList(opt: {
    sort?: SortParam
    page?: number
    pageSize?: number
    mangaId: string
  }) {
    return fetch(baseUrl + "/v2/chapter/cursor-list", {
      method: HttpMethod.POST,
      headers,
      body: JSON.stringify({
        sortOrder: opt.sort,
        page: opt.page || 1,
        pageSize: opt.pageSize || 30,
        mangaId: opt.mangaId
      })
    }).then((res) => res.json())
  },

  getFrames(chapterId: string) {
    return fetch(baseUrl + "/chapter/frames", {
      method: HttpMethod.POST,
      headers,
      body: JSON.stringify({ chapterId })
    }).then((res) => res.json())
  },

  getById(chapterId: string) {
    return fetch(baseUrl + "/chapter/" + chapterId, {
      headers
    }).then((res) => res.json())
  },

  patch(
    chapterId: string,
    data: Omit<ChapterShortRequestDto, "show" | "mangaId" | "chapterNum">
  ) {
    return fetch(baseUrl + "/chapter/" + chapterId, {
      method: HttpMethod.PATCH,
      headers,
      body: JSON.stringify(data)
    }).then((res) => res.text())
  },

  async create(data: ChapterShortRequestDto) {
    const idToken = await auth.currentUser?.getIdToken()
    return fetch(baseUrl + "/v2/chapter-private", {
      method: HttpMethod.POST,
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      },
      body: JSON.stringify(data)
    }).then(async (res) => {
      if (!res.ok) {
        const json = (await res.json()) as ServerError
        throw new Error(json.message)
      }

      return res.text()
    })
  },

  delete(chapterId: string) {
    return fetch(baseUrl + "/chapter/" + chapterId, {
      method: HttpMethod.DELETE,
      headers
    }).then((res) => res.text())
  },

  patchResources(
    chapterId: string,
    data: ChapterShortRequestDto["resourceIds"]
  ) {
    return fetch(baseUrl + "/chapter/" + chapterId + "/resources", {
      method: HttpMethod.DELETE,
      headers,
      body: JSON.stringify({ resourceIds: data })
    }).then((res) => res.text())
  },

  getCounters(mangaId: string): Promise<MangaCounters> {
    return fetch(baseUrl + "/chapter/counters/" + mangaId, {
      headers
    }).then((res) => res.json())
  }
}
