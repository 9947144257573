import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material"
import { GrayToken, PrimaryToken } from "../utils/tokens"

export const MyTable = styled("table")(({ theme }) => ({
  borderLeft: `1px solid ${GrayToken[50]}`,
  borderRight: `1px solid ${GrayToken[50]}`,
  borderTop: `1px solid ${GrayToken[50]}`,
  width: "100%"
}))

export const MyTableBody = styled("tbody")(({ theme }) => ({}))
export const MyTableHead = styled("thead")(({ theme }) => ({
  tr: {
    background: GrayToken[25]
  }
}))
export const MyTableCell = styled("td")(({ theme }) => ({
  fontSize: 14,
  padding: "12px 16px"
}))
export const MyTableRow = styled("tr")(({ theme }) => ({
  borderBottom: `1px solid ${GrayToken[50]}`
}))
