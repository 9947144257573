import React from "react"
import { FiRefreshCcw } from "react-icons/fi"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router"
import { useTranslatorEditorContext } from "../../context/translator-editor"
import { TranslatorJobsService } from "../../services/TranslatorJobsService"
import { TranslatorJobRequestDto } from "../../types/translator"
import { MyButton } from "../Button"
import { MyLoader } from "../Loader"
import ScatterLine from "./ScatterLine"

export default function ScatterLineList() {
  // =================================================
  // State
  // =================================================

  const { data, isLoading, handleResetScatterLines, scatteredLinesOptions } =
    useTranslatorEditorContext()
  const { translatorJobId, translatorJobContainerId } = useParams()
  const queryClient = useQueryClient()

  // =================================================
  // Mutations
  // =================================================

  const {
    isLoading: mutating,
    error,
    mutate
  } = useMutation({
    mutationFn: (data: TranslatorJobRequestDto) =>
      TranslatorJobsService.patch(data, translatorJobId as string),
    onSuccess: (_, vars) => {
      queryClient.setQueryData(["translator-job", translatorJobId], {
        ...data,
        assets: {
          ...data?.assets,
          ...(vars.translatedSentences && {
            translatedSentences: vars.translatedSentences
          }),
          ...(vars.textBlocksOptions && {
            textBlocksOptions: vars.textBlocksOptions
          })
        },
        status: vars.status
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["translator-jobs", translatorJobContainerId]
      })
    }
  })

  const handleMutate = () => {
    mutate({
      scatteredLinesOptions,
      status: "RESTART_WITH_OPTIONS"
    })
  }

  return (
    <div className="pb-6">
      <div className="flex items-center justify-between mx-6">
        <p className="text-gray-700 font-bold">Лінії Тексту</p>
        <div className="flex items-center">
          <MyButton
            disabled={mutating}
            isLoading={mutating}
            size="small"
            variant="transparent"
            onClick={() => handleMutate()}
          >
            <FiRefreshCcw className="mr-3" />
            Restart
          </MyButton>
        </div>
      </div>

      <div className={`mt-3`}>
        {scatteredLinesOptions?.mergeScatteredLinesIndexes?.map(
          (mergedScatterLine, i) => (
            <ScatterLine key={i} scatterLines={mergedScatterLine} index={i} />
          )
        )}
      </div>

      {isLoading && (
        <div className="my-12">
          <MyLoader size={36} />
        </div>
      )}

      <button
        onClick={() => handleResetScatterLines()}
        className="text-primary-400 hover:text-primary-600 transition-all duration-100 ease-in-out mx-6 mt-3"
      >
        Reset
      </button>
    </div>
  )
}
