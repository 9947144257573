import { Outlet } from "react-router"
import SignIn from "../components/auth/SignIn"
import Header from "../components/layout/Header"
import RequireAuth from "./RequireAuth"

export default function Root() {
  return (
    <RequireAuth>
      <div className="min-h-screen flex flex-col">
        <Header />
        <Outlet />
      </div>
    </RequireAuth>
  )
}
