import MangaJobList from "../components/parser/MangaJobList"

export default function Parser() {
  return (
    <div className="container mx-auto py-6 flex-grow">
      <p className="text-lg font-bold text-gray-700 mb-6">Парсер</p>
      <MangaJobList />
    </div>
  )
}
