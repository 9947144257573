import { useForm } from "react-hook-form"
import { MyTextFieldC } from "../MyTextfield"
import { yupResolver } from "@hookform/resolvers/yup"

import * as yup from "yup"
import Social from "./Social"
import { useAuth } from "../../context/auth"
import { useMutation } from "react-query"
import { FirebaseError } from "firebase/app"
import { Typography } from "@mui/material"
import { MyButton } from "../Button"
import { UserCredential } from "firebase/auth"
import { useNavigate } from "react-router"
import { MyLoader } from "../Loader"

interface Fields {
  email: string
  password: string
}

const signInSchema = yup.object({
  email: yup
    .string()
    .email("Невірний формат E-mail")
    .required("Обов`язкове поле"),
  password: yup
    .string()
    .min(8, "Пароль має містити 8 символів")
    .required("Обов`язкове поле")
})

export default function SignIn() {
  const { control, handleSubmit } = useForm<Fields>({
    resolver: yupResolver(signInSchema)
  })

  const { user, isLoading: isLoadingUser } = useAuth()

  const navigate = useNavigate()

  const { signInWithEmail } = useAuth()
  const { mutate, error, isLoading } = useMutation<
    UserCredential,
    FirebaseError,
    Fields
  >({
    mutationFn: ({ email, password }: Fields) =>
      signInWithEmail(email, password)
  })

  if (user && !isLoading) navigate("/")

  return (
    <form
      onSubmit={handleSubmit(({ email, password }) => {
        mutate({ email, password })
      })}
    >
      <MyTextFieldC
        control={control}
        name="email"
        muiProps={{
          fullWidth: true,
          placeholder: "E-mail"
        }}
      />
      <MyTextFieldC
        control={control}
        name="password"
        muiProps={{
          fullWidth: true,
          placeholder: "Пароль",
          className: "mt-2",
          type: "password"
        }}
      />
      <MyButton
        disabled={isLoading}
        type="submit"
        fullWidth
        size="medium"
        className="mt-4"
        variant="accent"
      >
        {isLoading ? <MyLoader /> : "Увійти"}
      </MyButton>
      {error && (
        <Typography className="mt-3" color="error.main">
          Помилка: {error.code}
        </Typography>
      )}
      <Social />
    </form>
  )
}
