import {
  alpha,
  Box,
  Fade,
  styled,
  ThemeProvider,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from "@mui/material"
import { MangaResponseDto, MangaType } from "../../types"
import config from "../../config.json"
import { FiBookmark, FiEye, FiHeart, FiStar } from "react-icons/fi"
import { darkTheme, theme } from "../../utils/theme"
import React, { PropsWithChildren } from "react"
import colors from "tailwindcss/colors"

import { truncate } from "../../utils"
import { useQuery } from "react-query"
import { MyLoader } from "../Loader"
import { MyChip } from "../Chip"
import { MangaService } from "../../services/MangaService"

export const MangaPreviewTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: alpha(colors.zinc[50], 0.5)
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: alpha(colors.zinc[900], 0.85),
    minWidth: 420,
    padding: "24px",
    fontSize: 16
  }
}))

export const MangaPreviewPopup: React.FC<{ mangaId: string }> = ({
  mangaId
}) => {
  const { data, isLoading, error } = useQuery<MangaResponseDto, Error>({
    queryFn: () => MangaService.getById(mangaId),
    queryKey: ["manga", mangaId]
  })

  return (
    <ThemeProvider theme={darkTheme}>
      {data && (
        <>
          <div className="text-md font-bold">
            {data.title} - {`[ ${data.translationStatus} ]`}
          </div>
          <div className="mt-3 text-gray-200">{data.alternativeTitle}</div>
          <div className="flex my-6 items-center">
            <div className="flex items-center">
              <FiEye size={18} className="mr-3" />
              {data.views || 0}
            </div>
            <div className="flex items-center ml-6">
              <FiHeart size={18} className="mr-3" />
              {data.likes || 0}
            </div>
            <div className="flex items-center ml-6">
              <FiBookmark size={18} className="mr-3" />
              {data.bookmarked || 0}
            </div>
            <div className="flex items-center ml-6">
              <FiStar size={18} className="mr-3" />
              {data.rateScore || 0}
            </div>
          </div>
          <div className="flex flex-wrap">
            {data.genres.map((item, i) => (
              <MyChip size="small" label={item} className="mr-4 mt-2" key={i} />
            ))}
            {data.tags.map((item, i) => (
              <MyChip size="small" label={item} className="mr-4 mt-2" key={i} />
            ))}
          </div>
          <div className="mt-6 text-gray-100">
            {truncate(data.description, 220)}
          </div>
        </>
      )}
      {isLoading && (
        <div className="my-3 flex justify-center">
          <MyLoader size="24px" />
        </div>
      )}
    </ThemeProvider>
  )
}

interface Props extends PropsWithChildren {
  mangaId: string
}
export const MangaPreview: React.FC<Props> = ({ children, mangaId }) => {
  return (
    <MangaPreviewTooltip
      placement="right-start"
      enterDelay={650}
      enterNextDelay={650}
      leaveDelay={0}
      title={<MangaPreviewPopup mangaId={mangaId} />}
    >
      <span>{children}</span>
    </MangaPreviewTooltip>
  )
}
