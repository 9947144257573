import { alpha, Chip, ChipProps, styled } from "@mui/material"
import colors from "tailwindcss/colors"
import { ChapterJobsStatuses } from "../types/parser"
import { TranslatorJobStatus } from "../types/translator"
import {
  ErrorToken,
  FontFamily,
  GrayToken,
  GreenToken,
  IndigoToken,
  WarningToken
} from "../utils/tokens"

interface Props extends Omit<ChipProps, "variant"> {
  variant?: TranslatorJobStatus | "colorless"
}

export const MyChip = styled(({ variant, ...props }: Props) => (
  <Chip {...props} />
))(({ theme, variant }) => ({
  backgroundColor:
    theme.palette.mode == "light"
      ? alpha(colors.zinc[50], 0.5)
      : alpha(colors.zinc[50], 0.15),

  ...(variant === "NONE" && {
    backgroundColor: GrayToken[25],
    color: GrayToken[600]
  }),
  ...(variant === "PROCESSING" && {
    backgroundColor:
      theme.palette.mode === "light"
        ? WarningToken[50]
        : alpha(WarningToken[400], 0.25),
    color:
      theme.palette.mode === "light" ? WarningToken[500] : WarningToken[400]
  }),
  ...(variant === "DONE" && {
    backgroundColor:
      theme.palette.mode === "light"
        ? GreenToken[50]
        : alpha(GreenToken[400], 0.25),
    color: theme.palette.mode === "light" ? GreenToken[500] : GreenToken[400]
  }),
  ...(variant === "CANCELLED" && {
    backgroundColor:
      theme.palette.mode === "light"
        ? ErrorToken[50]
        : alpha(ErrorToken[400], 0.25),
    color: theme.palette.mode === "light" ? ErrorToken[500] : ErrorToken[400]
  }),
  ...(variant === "QUEUED" && {
    backgroundColor:
      theme.palette.mode === "light"
        ? IndigoToken[50]
        : alpha(IndigoToken[400], 0.25),
    color: theme.palette.mode === "light" ? IndigoToken[500] : IndigoToken[400]
  }),
  ...(variant === "RE_RENDER" && {
    backgroundColor:
      theme.palette.mode === "light"
        ? WarningToken[50]
        : alpha(WarningToken[400], 0.25),
    color:
      theme.palette.mode === "light" ? WarningToken[500] : WarningToken[400]
  }),
  ...(variant === "RESTART_WITH_OPTIONS" && {
    backgroundColor:
      theme.palette.mode === "light"
        ? WarningToken[50]
        : alpha(WarningToken[400], 0.25),
    color:
      theme.palette.mode === "light" ? WarningToken[500] : WarningToken[400]
  }),
  ...(variant === "PENDING" && {
    backgroundColor:
      theme.palette.mode === "light"
        ? IndigoToken[50]
        : alpha(IndigoToken[400], 0.25),
    color: theme.palette.mode === "light" ? IndigoToken[500] : IndigoToken[400]
  }),
  fontSize: 14,
  borderRadius: 4,
  fontFamily: FontFamily.medium.join(","),
  padding: "4px",
  height: "auto !important"
}))
