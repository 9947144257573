import { TextField, styled, TextFieldProps, alpha } from "@mui/material"
import { Control, Controller } from "react-hook-form"
import { ErrorToken, GrayToken, PrimaryToken } from "../utils/tokens"

export const MyTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor:
        theme.palette.mode == "light"
          ? GrayToken[100]
          : alpha(GrayToken[100], 0.2)
    },

    "&.Mui-focused fieldset, &:hover fieldset": {
      borderColor: PrimaryToken[400],
      borderWidth: 1
    },

    "&.Mui-focused.Mui-error fieldset, &.Mui-error fieldset": {
      borderColor: ErrorToken[300],
      borderWidth: 1
    },

    "&.Mui-disabled fieldset": {
      borderColor:
        theme.palette.mode == "light"
          ? GrayToken[100]
          : alpha(GrayToken[100], 0.2)
    },

    "&.Mui-disabled": {
      background: theme.palette.mode === "dark" ? GrayToken[700] : GrayToken[25]
    }
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: ErrorToken[300]
  }
}))

export const MyTextFieldC: React.FC<{
  control: Control<any>
  name: string
  muiProps?: TextFieldProps
}> = ({ control, name, muiProps }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, name, ref },
        fieldState: { error }
      }) => (
        <>
          <MyTextField
            {...muiProps}
            inputRef={ref}
            onChange={onChange}
            value={value}
            name={name}
            error={!!error}
            helperText={error?.message}
          />
        </>
      )}
    />
  )
}
