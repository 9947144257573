import React, { useState } from "react"
import { FiScissors, FiTrash } from "react-icons/fi"
import config from "../../../config.json"
import { MyIconButton } from "../../Button"
import { MyMenu, MyMenuItem } from "../../Menu"
import { IoEllipsisHorizontalSharp } from "react-icons/io5"
import { Divider } from "@mui/material"

type Props = {
  rscId: string
  frames: string[]
  moveFrame: (oldIndex: number, nexIndex: number) => void
  handleDelete: (id: string) => void
  index: number
}

export default function Frame({
  rscId,
  frames,
  handleDelete,
  moveFrame,
  index
}: Props) {
  //  ======================================================================
  //  State
  //  ======================================================================

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  //  ======================================================================
  //  Handlers
  //  ======================================================================

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMove = (i: number) => {
    setAnchorEl(null)
    moveFrame(index, i)
  }

  const handleClose = (i: number) => {
    setAnchorEl(null)
  }

  return (
    <div className="hm-chapter-rsc__create-translation-job">
      <div className="mb-6 flex items-center justify-between">
        <div className="flex items-center">
          <p className="text-gray-700">Фрейм №{index}</p>
        </div>
        <div className="flex items-center">
          <MyIconButton disabled variant="colorless" className="mr-2">
            <FiScissors size={18} />
          </MyIconButton>
          <MyIconButton onClick={handleClick} variant="colorless">
            <IoEllipsisHorizontalSharp size={18} />
          </MyIconButton>
        </div>
      </div>
      <div className="relative w-full border border-gray-100">
        <img
          src={config.resource + `/${rscId}`}
          alt=""
          className="h-fit w-full rounded-lg"
        />
      </div>
      <MyMenu
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom"
        }}
        transformOrigin={{
          vertical: -15,
          horizontal: "left"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: 245
          }
        }}
      >
        <MyMenuItem onClick={() => handleDelete(rscId)}>
          <div className="w-9 h-9 bg-error-50 mr-4 rounded-lg flex items-center justify-center">
            <FiTrash size={20} className="text-error-500" />
          </div>
          Видалити
        </MyMenuItem>
        {frames.map((_, i) => (
          <MyMenuItem
            disabled={i == index}
            onClick={() => handleMove(i)}
            key={i}
          >
            <div className="w-9 h-9 bg-gray-25 mr-4 rounded-lg flex items-center justify-center">
              {i}
            </div>
            Перемістити
          </MyMenuItem>
        ))}
      </MyMenu>
    </div>
  )
}
