import { useAuth } from "../context/auth"
import { useLocation, Navigate } from "react-router-dom"
import { MyLoader } from "../components/Loader"

export default function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth()
  let location = useLocation()

  if (auth.isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <MyLoader size={48} />
      </div>
    )
  }

  if (
    (!auth.user && !auth.isLoading) ||
    (auth.user && !auth.user.role.includes("EDITOR"))
  ) {
    // @ts-ignore
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}
