import { Box, Collapse, FormControlLabel, Checkbox } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { FiChevronDown, FiChevronUp, FiType } from "react-icons/fi"
import { useTranslatorEditorContext } from "../../context/translator-editor"
import { GrayToken } from "../../utils/tokens"
import { MyTextField } from "../MyTextfield"

type Props = {
  index: number
}

export default function TextLayer({ index }: Props) {
  const [open, setOpen] = useState(false)
  const {
    textBlocks,
    textExclusion,
    textFonts,
    handleTextBlocks,
    handleTextFonts,
    handleTextExclusion
  } = useTranslatorEditorContext()

  const handleChangeText = useCallback(
    (val: string) => {
      handleTextBlocks(val, index)
    },
    [index]
  )

  const handleChangeFontSize = useCallback(
    (val: string) => {
      handleTextFonts(
        {
          fontSize: parseInt(val),
          fontWeight: textFonts[index].fontWeight
        },
        index
      )
    },
    [index, textFonts]
  )

  const handleChangeFontWeight = useCallback(
    (val: string) => {
      handleTextFonts(
        {
          fontSize: textFonts[index].fontSize,
          fontWeight: parseInt(val)
        },
        index
      )
    },
    [index, textFonts]
  )

  const isExclude = !!textExclusion[index].exclude

  return (
    <div>
      <button
        onClick={() => setOpen((open) => !open)}
        className={`w-full group flex items-center px-6 py-3 hover:text-primary-500 hover:bg-primary-25 transition-all duration-100 ease-in-out text-gray-600`}
      >
        <FiType
          size={18}
          className="mr-3 text-gray-400 group-hover:text-primary-500 transition-all duration-100 ease-in-out"
        />
        Text layer #{index}
      </button>
      <Collapse unmountOnExit mountOnEnter in={open}>
        <div className={"px-6 py-3"}>
          <div className={isExclude ? `opacity-50` : undefined}>
            <p className="text-gray-400 text-sm uppercase font-medium mb-2">
              Text Block
            </p>
            <MyTextField
              fullWidth
              placeholder="Текст"
              value={textBlocks[index]}
              multiline
              onChange={(e) => handleChangeText(e.target.value)}
              disabled={isExclude}
            />
            <div className="grid grid-cols-2 gap-3 mt-4">
              <div>
                <p className="text-gray-400 text-sm uppercase font-medium mb-2">
                  Font Size
                </p>
                <MyTextField
                  fullWidth
                  placeholder="Value"
                  value={textFonts[index].fontSize}
                  type="number"
                  onChange={(e) => handleChangeFontSize(e.target.value)}
                  disabled={isExclude}
                />
              </div>
              <div>
                <p className="text-gray-400 text-sm uppercase font-medium mb-2">
                  Font Weight
                </p>
                <MyTextField
                  fullWidth
                  placeholder="Value"
                  value={textFonts[index].fontWeight}
                  onChange={(e) => handleChangeFontWeight(e.target.value)}
                  type="number"
                  disabled={isExclude}
                />
              </div>
            </div>
          </div>
          <FormControlLabel
            className="text-gray-400 mt-4"
            control={<Checkbox />}
            label="Exclude"
            checked={isExclude}
            onChange={(e) => handleTextExclusion(index)}
          />
        </div>
      </Collapse>
    </div>
  )
}
