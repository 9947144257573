import { Divider } from "@mui/material"
import { useTranslatorEditorContext } from "../../context/translator-editor"

import ExtraOptions from "./ExtraOptions"
import ScatterLineList from "./ScatterLineList"
import TextLayers from "./TextLayerList"

export default function LeftSidebar() {
  const { data } = useTranslatorEditorContext()

  return (
    <div
      className={`flex flex-col h-full mt-6${
        data?.status !== "DONE" && data?.status !== "PENDING"
          ? ` opacity-50 pointer-events-none`
          : ``
      }`}
    >
      <TextLayers />
      <Divider className="mx-6 my-6" />
      <ScatterLineList />
    </div>
  )
}
