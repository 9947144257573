import urls from "../config.json"
import { ServerError } from "../types"
import {} from "../types/parser"
import {
  TranslatorJob,
  TranslatorJobContainerList,
  TranslatorJobContainerRequestDto,
  TranslatorJobContainerResponseDto,
  TranslatorJobRequestDto,
  TranslatorMangaJobList
} from "../types/translator"
import { HttpMethod } from "../utils/enums"
import { auth } from "../utils/firebase"
const baseUrl = urls["service-aggregation"]

//  ======================================================================
//  User Service
//  ======================================================================

const headers = {
  "Content-Type": "application/json"
}

export const TranslatorContainerService = {
  async getById(id: string): Promise<TranslatorJobContainerResponseDto> {
    const idToken = await auth.currentUser?.getIdToken()

    return fetch(baseUrl + "/translation-job-container/" + id, {
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      const json = await res.json()
      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }
      return json
    })
  },

  async patch(id: string): Promise<string> {
    const idToken = await auth.currentUser?.getIdToken()
    return fetch(baseUrl + "/translation-job-container/done/" + id, {
      method: HttpMethod.PATCH,
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      if (!res.ok) {
        const json = await res.json()
        throw new Error((json as ServerError).message)
      }
      return await res.text()
    })
  },

  async getList(
    mangaJobId: string,
    _: string
  ): Promise<TranslatorJobContainerList> {
    const idToken = await auth.currentUser?.getIdToken()

    return fetch(baseUrl + "/translation-job-container/list", {
      method: "POST",
      body: JSON.stringify({
        page: 1,
        pageSize: 20,
        filterBy: "translationJobMangaId",
        filterValue: mangaJobId,
        sorting: "DESC"
      }),
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      const json = await res.json()
      if (!res.ok) {
        throw new Error((json as ServerError).message)
      }
      return json
    })
  },

  async create(
    body: TranslatorJobContainerRequestDto,
    _: string
  ): Promise<string> {
    const idToken = await auth.currentUser?.getIdToken()
    return fetch(baseUrl + "/translation-job-container", {
      body: JSON.stringify(body),
      method: HttpMethod.POST,
      headers: {
        ...headers,
        "x-id-token": idToken || ""
      }
    }).then(async (res) => {
      if (!res.ok) {
        const json = await res.json()
        throw new Error((json as ServerError).message)
      }
      return await res.text()
    })
  }
}
