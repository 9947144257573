import SignIn from "../components/auth/SignIn"

export default function Login() {
  return (
    <div className="px-4 mx-auto md:container min-h-screen flex justify-center items-center">
      <div className="grid grid-cols-12">
        <div className="md:col-start-5 col-start-2 col-span-10 md:col-span-4">
          <p className="mb-6 flex items-center font-bold">
            <img className="w-9 mr-3" src="/imgs/swg-11.png" />
            Honey Root
          </p>
          <SignIn />
        </div>
      </div>
    </div>
  )
}
