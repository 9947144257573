import { DialogContent, DialogTitle } from "@mui/material"
import { enqueueSnackbar } from "notistack"
import { useState } from "react"
import { FiX } from "react-icons/fi"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router"
import { useTranslatorEditorContext } from "../../context/translator-editor"
import { ResourceService } from "../../services/ResourceService"
import { MyButton, MyIconButton } from "../Button"
import { MyUploader } from "../FileUploader"
import { LazyImageComponent } from "../LazyImageComponent"
import { MyModal } from "../Modal"

type Props = {
  handleClose: () => void
  isOpen: boolean
}

export default function ResourceReplace({ handleClose, isOpen }: Props) {
  //  ======================================================================
  //  State
  //  ======================================================================

  const [files, setFiles] = useState<File[]>()
  const { resource, resourceType } = useTranslatorEditorContext()
  const queryClient = useQueryClient()
  const { translatorJobId } = useParams()

  //  ======================================================================
  //  Mutation
  //  ======================================================================

  const { isLoading: isMutating, mutate } = useMutation({
    mutationFn: (formData: FormData) =>
      ResourceService.post(formData, resource),
    onSuccess: () => {
      enqueueSnackbar("Фрейм було успішно замінено!", {
        variant: "info"
      })

      queryClient.invalidateQueries({
        queryKey: ["translator-job-resource", translatorJobId, resourceType]
      })
    },
    onError: (e) => {
      enqueueSnackbar(
        "Сталася помилка! Спробуйте пізніше, або напишіть у тех. підтримку",
        {
          variant: "error"
        }
      )
    }
  })

  //  ======================================================================
  //  Handlers
  //  ======================================================================

  const handleFiles = (files: File[]) => setFiles(files)
  const handleMutation = () => {
    if (files && files.length !== 0) {
      const formData = new FormData()
      formData.append("file", files[0])
      mutate(formData)
    }
  }

  return (
    <MyModal
      maxWidth="md"
      fullWidth
      onClose={() => handleClose()}
      open={isOpen}
      scroll="body"
    >
      <DialogTitle>
        <div className="flex items-center justify-between">
          <p className="text-[18px] font-bold">Замінити фрейм</p>
          <MyIconButton onClick={() => handleClose()} variant="transparent">
            <FiX size={18} />
          </MyIconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <MyUploader
          title={files && files[0].name}
          options={{
            onDrop: (files) => handleFiles(files),
            accept: {
              "image/jpeg": [],
              "image/png": [],
              "image/webp": []
            },
            multiple: false
          }}
        />

        {files && files.length !== 0 && (
          <div className="mt-6">
            <LazyImageComponent
              style={{
                width: "100%",
                height: "auto"
              }}
              src={URL.createObjectURL(files[0] || "")}
            />
          </div>
        )}

        <div onClick={handleMutation} className="mt-6">
          <MyButton
            disabled={isMutating}
            isLoading={isMutating}
            fullWidth
            variant="dark"
          >
            Завантажити
          </MyButton>
        </div>
      </DialogContent>
    </MyModal>
  )
}
